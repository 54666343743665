export function formatContainer(type,size) 
{
      if (!type || !size) {
        return 'Invalid container data';
      }
    
    
      // Check if the container type is 'Dry' and size is 20
      if(type === 1){
            if (size == '20') {
                  return `${size} FT`;
            }
      
            if (size == '40') {
                  return `${size} FT`;
            }
      
            if (size == '40hc') {
                  return `40 FT HC`;
            }
      }

      if(type === 2)
      {
            if (size == '20') {
                  return `${size} RF`;
            }
      
            if (size == '40hc') {
                  return `40 RF`;
            }
      }

      if(type === 4)
      {
            if (size == '20') {
                  return `${size} FT`;
            }
      
            if (size == '40') {
                  return `${size} FT`;
            }
      
            if (size == '40hc') {
                  return `40 FT HC`;
            }
      }

      if(type === 5)
      {
            if (size == '20') {
                  return `${size} OT`;
            }
      
            if (size == '40') {
                  return `${size} OT`;
            }
      
            if (size == '40hc') {
                  return `40 OT HC`;
            }
      }

      if(type === 6)
      {
            if (size == '20') {
                  return `${size} FR`;
            }
      
            if (size == '40') {
                  return `${size} FR`;
            }
      
            if (size == '40hc') {
                  return `40 FL HC`;
            }

            if (size == '40sr') {
                  return `40 SR`;
            }
      }

    }

    // container format with qty details
    export function formatContainerWithQty(type,size,qty) {
      
      if (!type || !size || !qty ) {
        return 'Invalid container data';
      }
    
    
      // Check if the container type is 'Dry' and size is 20
      if(type === 1){
            if (size === '20') {
                  return `$qty` `X` `${size} FT`;
            }
      
            if (size === '40') {
                  return `${size} FT`;
            }
      
            if (size === '40hc') {
                  return `40 FT HC`;
            }
      }

      if(type === 2)
      {
            if (size === '20') {
                  return `${size} RF`;
            }
      
            if (size === '40hc') {
                  return `40 RF`;
            }
      }

      if(type === 4)
      {
            if (size === '20') {
                  return `${size} FT`;
            }
      
            if (size === '40') {
                  return `${size} FT`;
            }
      
            if (size === '40hc') {
                  return `40 FT HC`;
            }
      }

      if(type === 5)
      {
            if (size === '20') {
                  return `${size} OT`;
            }
      
            if (size === '40') {
                  return `${size} OT`;
            }
      
            if (size === '40hc') {
                  return `40 OT HC`;
            }
      }

      if(type === 6)
      {
            if (size === '20') {
                  return `${size} FR`;
            }
      
            if (size === '40') {
                  return `${size} FR`;
            }
      
            if (size === '40hc') {
                  return `40 FL HC`;
            }

            if (size === '40sr') {
                  return `40 SR`;
            }
      }
      
    
      // Add more conditions here if needed for different types and sizes
      // return `${size} FT (${type} container)`;
    }

