import React, {Component, Fragment} from "react";
import {CustomInput} from "reactstrap";
import {slideDown, slideUp} from "../../routes/desktop/bl/anim";
import {containerTypes, REFRIGERATED} from "../../constants/containerTypes";
import {formatContainer} from "../../constants/containerUtils";

class ContainerTableRow extends Component {
    state = {
        expanded: false,
        isLoading: true,
        containerSelected: []
    }

    componentDidMount() {
        let containerDetails = this.props.container;
        let containerSelected = this.props.containerSelected;
        this.setState({
            containerDetails: containerDetails,
            containerRowDetails: containerDetails.containers,
            isLoading: false,
            containerSelected
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.container.book_id !== this.props.container.book_id) {
            let containerDetails = this.props.container;
            let containerSelected = this.props.containerSelected;
            this.setState({
                containerDetails: containerDetails,
                containerRowDetails: containerDetails.containers,
                isLoading: false,
                containerSelected
            });
        }
    }

    toggleExpander = (e) => {
        if (!this.state.expanded) {
            this.setState(
                {expanded: true},
                () => {
                    if (this.refs.expanderBody) {
                        slideDown(this.refs.expanderBody);
                    }
                }
            );
        } else {
            slideUp(this.refs.expanderBody, {
                onComplete: () => {
                    this.setState({expanded: false});
                }
            });
        }
    }

    containerSelectHandler = (event, container) => {
        this.props.containerSelectHandler(event, container);
    };

    render() {
        let containerRowDetails = []; 
        if (this.state.containerRowDetails) {
            
            
            for (let container of this.state.containerRowDetails) {
                let charge = "container_value_" + container.container_size;
                let details =
                    <tr className={(container.bl_id === null && this.props[charge] === null) || (container.bl_id === null && this.props[charge] !== null && parseFloat(this.props[charge]) === parseFloat(container.freight_value)) ? "" : "bl_container_tr"}>
                        <td>
                        {(container.bl_id === null && this.props[charge] === null && (container.verified_gross_mass)) || (container.bl_id === null && this.props[charge] !== null && parseFloat(this.props[charge]) === parseFloat(container.freight_value) && (container.verified_gross_mass)) ?
                                <CustomInput
                                    type="checkbox"
                                    id={"container_" + container.id}
                                    onChange={(event) => this.containerSelectHandler(event, container)}
                                    checked={this.props.containerSelected && this.props.containerSelected.includes(parseInt(container.id))}/>

                                : <div/>}
                            {/* {(container.verified_gross_mass ? container.container_number : "NA") + " ( " + (container.container_size === "40hc" ? "40" : container.container_size) + (parseInt(container.container_type) === REFRIGERATED && container.container_size === "40hc" ? "RF HC" : (parseInt(container.container_type) === REFRIGERATED ? "RF" : (container.container_size === "40hc" ? "HC" : "FT"))) + " )" + (container.verified_gross_mass ? "" : " VGM Not Done" )} */}
                            { (container.verified_gross_mass ? container.container_number : "NA") + " ( " +   formatContainer(parseInt(container.container_type),container.container_size) + " )" + (container.verified_gross_mass ? "" : " VGM Not Done" )}
                        </td> 
                        <td>{containerTypes[container.container_type]}</td>
                        <td>{container.verified_gross_mass ? container.verified_gross_mass : "NA"}</td>
                        <td>{container.cargo_weight ? container.cargo_weight : "NA"}</td>
                        <td>{container.no_of_pkgs ? container.no_of_pkgs : "NA"}</td>
                    </tr>

                containerRowDetails.push(details);
            }
        }

        return !this.state.isLoading ?
            <Fragment>
                <tr className="container-main-tr" key="main" onClick={this.toggleExpander}>
                    <td><i
                        className={this.state.expanded ? "fa fa-minus accordion-icon" : "fa fa-plus accordion-icon"}/>
                    </td>
                    <td className="uk-text-nowrap">{this.state.containerDetails.book_id}</td>
                    <td>{this.state.containerDetails.pol.port_name}</td>
                    <td>{this.state.containerDetails.pod.port_name}</td>
                    <td>{this.state.containerDetails.etd_pol}</td>
                    <td>{this.state.containerDetails.eta_pod}</td>
                </tr>
                {this.state.expanded && (
                    <tr className="expandable" key="tr-expander">
                        <td className="uk-background-muted" colSpan={6}>
                            <div ref="expanderBody" className="inner uk-grid">
                                <div className="uk-width-1-4 uk-text-center">
                                    {/*<img className="uk-preserve-width uk-border-circle" src={user.picture.large} alt="avatar" />*/}
                                </div>
                                <table>
                                    <thead>
                                    <th>
                                        Container Number
                                    </th>
                                    <th>Container Type</th>
                                    <th>Verified Gross Weight</th>
                                    <th>Cargo Weight</th>
                                    <th>No of Packages</th>
                                    </thead>
                                    <tbody>
                                    {containerRowDetails}
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                )}

            </Fragment> : <Fragment/>

    }
}

export default ContainerTableRow;