import React, {Component, Fragment} from "react";
import {AvField, AvForm, AvGroup, AvRadioGroup, AvRadio} from "availity-reactstrap-validation";
import collect from "collect.js";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {containerTypes, REFRIGERATED} from "../../../../constants/containerTypes";
import moment from "moment";
import {API_URL, AWS_S3_URL} from "../../../../constants/apiConstants";
import {maxUploadFileSize} from "../../../../constants/defaultValues";
import ButtonLoader from "../../../../util/ButtonLoader";
import Select from "react-select";
import CustomFeedback from "../../../../components/formValidations/CustomFeedback";
import CustomInput from "reactstrap/es/CustomInput";
import Request from "../../../../util/api.service";
import PdfIcon from "../../../../assets/images/pdf-icon.png";
import Auth from "../../../../util/auth.service";
import {NotificationManager} from "react-notifications";
import {formatContainer} from "../../../../constants/containerUtils";

class VgmForm extends Component {

    state = {
        isoCodeList: [],
        booking: null,
        containerId: null,
        packageType: null,
        selectedContainers: null,
        tareWeightError: null,
        cargoWeightError: null,
        WeightError: null,
        weighingTimeError: null,
        weighingTime: new Date(),
        cargo_weight: null,
        tare_weight: null,
        max_weight_limit: null,
        cargo_weight_unit: 1,
        tare_weight_unit: 1,
        max_weight_limit_unit: 1,
        verified_gross_mass: 0,
        VGMdocLabel: null,
        VGMdocName: 'Select File',
        VGMdoc: null,
        VGmdocExistingFile: null,
        VGMauthorizationLetterName: 'Select File',
        VGMauthorizationLetterExistingFile: null,
        VGMauthorizationLetterDoc: null,
        VGMweightDeclarationDocName: 'Select File',
        VGMweightDeclarationExistingFile: null,
        VGMweightDeclarationDoc: null,
        selectedShipper: null,
        sendRequestToOdex: 0,
        weighHours: null,
        weighMinute: null,
        showDownloadPdf: false,
        submitType: 0
    };

    componentDidMount() {
        let booking = this.props.booking;
        let containerId = this.props.containerId;
        let containers = collect(booking.containers);
        containers = containers.where('id', containerId);
        containers = containers.toArray();
        containers = containers[0];
        this.getIsoCodes(containers);
        let company_type = parseInt(booking.user.company.company_type);
        let container_size = (containers.container_size === "40hc") ? (booking.container_type_id === REFRIGERATED ? '40 RF HC' : '40 HC') : containers.container_size + (booking.container_type_id === REFRIGERATED ? ' RF' : ' FT');
        let selectedShipper = null;
        if (booking.shipper) {
            selectedShipper = {
                label: booking.shipper.company_name,
                value: booking.shipper.id,
                key: booking.shipper.id,
                reg_no: booking.shipper.registration_no
            };
        }
        let showDownloadPdf = containers.container_number !== null;
        this.setState({
            showDownloadPdf,
            containerId: containerId,
            selectedShipper,
            booking: booking,
            selectedContainers: containers,
            containerNumber: containers ? containers.container_number : "",
            weighingTime: containers.vgm_done_at ? moment(containers.vgm_done_at, "YYYY-MM-DD HH:mm").toDate() : new Date(),
            company_type,
            packageType: this.props.packageType,
            cargo_weight: containers.cargo_weight,
            tare_weight: containers.tare_weight,
            max_weight_limit: containers.max_weight_limit,
            verified_gross_mass: containers.verified_gross_mass,
            cargo_weight_unit: 1,
            tare_weight_unit: 1,
            max_weight_limit_unit: 1,
            container_freight_limit: (booking.containers[0].container_size === "20") ? booking['frt_20_wt_limit'] : (booking.containers[0].container_size === "40" ? booking['frt_40_wt_limit'] : booking['frt_40hc_wt_limit']),
            VGMdocLabel: 'Please attach weighbridge slip for container ' + container_size,
            VGmdocExistingFile: (containers['vgm_document'] != null && containers['vgm_document']['vgm']) ?
                <a href={AWS_S3_URL + containers['vgm_document']['vgm']} className="view-upload-file" target='_blank'
                   rel="noreferrer noopener">View
                    File</a> : null,
            VGMauthorizationLetterExistingFile: (containers['vgm_document'] != null && containers['vgm_document']['authorization_letter']) ?
                <a href={AWS_S3_URL + containers['vgm_document']['authorization_letter']} className="view-upload-file"
                   target='_blank'
                   rel="noreferrer noopener">View
                    File</a> : null,
            VGMweightDeclarationExistingFile: (containers['vgm_document'] != null && containers['vgm_document']['weight_declaration']) ?
                <a href={AWS_S3_URL + containers['vgm_document']['weight_declaration']} className="view-upload-file"
                   target='_blank'
                   rel="noreferrer noopener">View
                    File</a> : null
        });
    };

    getIsoCodes = (container) => {
        let container_size = (container.container_size === "40hc" ? '45' : container.container_size);
        Request.get(API_URL + 'iso-code/' + container_size)
            .then(response => {
                this.setState({isoCodeList: response.data.data});
            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    }

    handleChangeDateTime = (date) => {
        let weighHours = this.state.weighHours;
        let weighMinute = this.state.weighMinute;
        if (moment().diff(date, 'days') < 1) {
            weighHours = 0;
            weighMinute = 0;
        }
        this.setState({weighingTime: date, weighingTimeError: null, weighHours, weighMinute});
    };

    handleWeighTimeChange = (event, name) => {
        this.setState({[name]: event.target.value});
    };

    handleOdexRequest = (event) => {
        if (event.target.checked === false) {
            this.setState({sendRequestToOdex: 0});
        } else {
            this.setState({sendRequestToOdex: 1});
        }
    };

    checkCargoWeight = () => {
        if (this.state.max_weight_limit != null) {
            let freight_limit = ((this.state.container_freight_limit) * 1000) + 999;
            if ((parseFloat(this.state.cargo_weight) * (parseInt(this.state.cargo_weight_unit))) > (parseFloat(this.state.max_weight_limit) * parseInt(this.state.max_weight_limit_unit)) ||
                (parseFloat(this.state.tare_weight) * parseInt(this.state.tare_weight_unit)) + (parseFloat(this.state.cargo_weight) * parseInt(this.state.cargo_weight_unit)) > (parseFloat(this.state.max_weight_limit) * parseInt(this.state.max_weight_limit_unit))
                || (parseFloat(this.state.cargo_weight) * parseInt(this.state.cargo_weight_unit)) > freight_limit) {
                let cargoError = null;
                cargoError = "Cargo Weight should be less than Gross Weight";
                if ((parseFloat(this.state.cargo_weight) * parseInt(this.state.cargo_weight_unit)) > freight_limit) {
                    cargoError = "Cargo Weight should be less than Container limit";
                }
                this.setState({
                    cargoWeightError: cargoError,
                    type: null,
                    verified_gross_mass: ((parseFloat(this.state.tare_weight) * parseInt(this.state.tare_weight_unit)) + (parseFloat(this.state.cargo_weight) * parseInt(this.state.cargo_weight_unit))).toFixed(2)
                });
            } else {
                this.setState({cargoWeightError: null, WeightError: null}, this.checkTareWeight);
                this.setState({verified_gross_mass: ((parseFloat(this.state.tare_weight) * parseInt(this.state.tare_weight_unit)) + (parseFloat(this.state.cargo_weight) * parseInt(this.state.cargo_weight_unit))).toFixed(2)});
            }
        } else {
            this.setState({WeightError: "Please Enter Gross Weight"}, this.checkTareWeight);
        }
    };

    checkTareWeight = () => {
        if (this.state.max_weight_limit != null) {
            if ((parseFloat(this.state.tare_weight) * parseInt(this.state.tare_weight_unit)) > (parseFloat(this.state.max_weight_limit) * parseInt(this.state.max_weight_limit_unit)) ||
                (parseFloat(this.state.tare_weight) * parseInt(this.state.tare_weight_unit)) + (parseFloat(this.state.cargo_weight) * parseInt(this.state.cargo_weight_unit)) > (parseFloat(this.state.max_weight_limit) * parseInt(this.state.max_weight_limit_unit))) {
                this.setState({
                    tareWeightError: "Tare Weight should be less than Gross Weight",
                    type: null,
                    verified_gross_mass: ((parseFloat(this.state.tare_weight) * parseInt(this.state.tare_weight_unit)) + (parseFloat(this.state.cargo_weight) * parseInt(this.state.cargo_weight_unit))).toFixed(2)
                });
            } else {
                this.setState({tareWeightError: null, WeightError: null});
                this.setState({verified_gross_mass: ((parseFloat(this.state.tare_weight) * parseInt(this.state.tare_weight_unit)) + (parseFloat(this.state.cargo_weight) * parseInt(this.state.cargo_weight_unit))).toFixed(2)});
            }
        } else {
            this.setState({WeightError: "Please Enter Gross Weight"});
        }
    }

    checkWeight = (event, type, type_unit) => {
        new Promise((resolve, reject) => {
            if (type_unit !== '') {
                this.setState({[type_unit]: event.target.value});
            } else {
                let value = parseFloat(event.target.value);
                value = value.toFixed(2);
                this.setState({[type]: value});
            }
            resolve();
        }).then(() => {

            if (type === 'max_weight_limit' || type_unit === 'max_weight_limit_unit') {

                if (this.state.max_weight_limit != null) {
                    if (((parseFloat(this.state.tare_weight) * parseInt(this.state.tare_weight_unit)) + (parseFloat(this.state.cargo_weight) * parseInt(this.state.cargo_weight_unit)) > (parseInt(this.state.max_weight_limit_unit) * parseFloat(this.state.max_weight_limit)))) {
                        this.setState({WeightError: "Your Gross Weight exceeds maxium permissible limit "});
                    } else {
                        this.setState({WeightError: null}, this.checkCargoWeight);
                    }
                }
            }

            if (type === "cargo_weight" || type_unit === 'cargo_weight_unit') {
                this.checkCargoWeight();
            }

            if (type === "tare_weight" || type_unit === 'tare_weight_unit') {
                this.checkTareWeight();
            }
        });

    };

    getFilename = (file, docName, errorName, fileVariable, existingFile) => {
        var imagefile = document.querySelector('#' + file);
        this.setState({VGMdocError: null, VGMweightDeclarationDocError: null, VGMauthorizationLetterError: null});
        if (imagefile.files[0]) {
            var parts = imagefile.files[0].name.split('.');
            let allowed_extensions = ['pdf'];
            if (allowed_extensions.indexOf(parts[parts.length - 1].toLowerCase()) !== -1) {
                if (imagefile.files[0].size <= maxUploadFileSize) {
                    this.setState({
                        [fileVariable]: imagefile.files[0] ? imagefile.files[0] : '',
                        [docName]: imagefile.files[0] ? imagefile.files[0].shortName(30) : 'Upload VGM',
                        [errorName]: null,
                        [existingFile]: null
                    });
                } else {
                    this.setState({[errorName]: "The uploaded file is exceeding the maximum file size of 2 MB."});
                }
            } else {
                this.setState({[errorName]: "Please select valid file."});
            }
        }
    };

    handleContainerNumber = (event) => {
        let containerNumber = event.target.value.toUpperCase();
        this.setState({containerNumber});
    }

    handleChange = (name, error, selected) => {
        this.setState({[name]: selected, [error]: null});
    };

    submitVgm = () => {
        this.setState({submitType: 1});
        document.getElementById("save_button").click();
    };


    onSubmit = (event, errors, values) => {
        let weighingTimeError = null, shipperError = null, user = Auth.user;
        if (this.state.weighingTime) {
            let selectedDatetime = moment(this.state.weighingTime, 'DD-MM-YYYY HH:mm:ss');
            let currentDatetime = moment().format('YYYY-MM-DD HH:mm:ss');
            // if (!moment(moment().format('YYYY-MM-DD HH:mm:ss')).isAfter(moment(moment(this.state.weighingTime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')), 'minute')) {
            if (!selectedDatetime.isAfter(currentDatetime)) {
                weighingTimeError = "Date and time of Weighing should not be greater than current time";
            }
        }
        if (!this.state.selectedShipper && user.company.company_type === 2) {
            shipperError = "Please select Shipper.";
            errors.push(shipperError);
        }

        this.setState({shipperError, weighingTimeError});
        if (errors.length !== 0 && this.state.shipperError === null) {
            var error_element = document.getElementsByName(errors[0]);
            error_element[0].parentNode.parentNode.scrollIntoView();

        } else if (
            errors.length === 0 &&
            this.state.cargoWeightError === null &&
            this.state.tareWeightError === null &&
            this.state.WeightError === null &&
            this.state.shipperError === null &&
            this.state.weighingTimeError === null
        ) {

            let vgm_done_at = moment(this.state.weighingTime).format("YYYY-MM-DD HH:mm:ss");
            if (this.state.weighHours && this.state.weighMinute) {
                vgm_done_at = moment(this.state.weighingTime).format("YYYY-MM-DD") + ":" + this.state.weighHours + ":" + this.state.weighMinute + ":00";
            }

            let data = {
                submitType: this.state.submitType,
                weighbridge_address_1: values.weighbridge_address_1,
                weighbridge_address_2: values.weighbridge_address_2,
                weighbridge_address_3: values.weighbridge_address_3,
                vgm: this.state.VGMdoc,
                authorization_letter: this.state.VGMauthorizationLetterDoc,
                weight_declaration: this.state.VGMweightDeclarationDoc,
                authorised_person_name: values.authorised_person_name,
                cargo_type: this.state.booking.cargo_type,
                container_id: values.container_id,
                cro_no: values.cro_no,
                container_number: values.container_number,
                container_size: this.state.selectedContainers.container_size,
                cargo_weight: values.cargo_weight * parseInt(values.cargo_weight_unit),
                max_weight_limit: values.max_weight_limit * parseInt(values.max_weight_limit_unit),
                authorised_person_contact: values.authorised_person_contact,
                authorised_person_designation: values.authorised_person_designation,
                shipper_reg_no: values.shipper_reg_no,
                shipper_id: this.state.selectedShipper ? this.state.selectedShipper.value : "",
                no_of_pkgs: values.no_of_pkgs,
                tare_weight: values.tare_weight * parseInt(values.tare_weight_unit),
                container_type: this.state.booking.commodity.container_type_id,
                un_no: this.state.booking.un_number,
                dg_class: this.state.booking.cargo_haz_class,
                verified_gross_mass: values.verified_gross_mass,
                vgm_method: values.vgm_method,
                vgm_done_at: vgm_done_at,
                weighbridge_name: values.weighbridge_name,
                weighbridge_reg_no: values.weighbridge_reg_no,
                weighbridge_slip_no: values.weighbridge_slip_no,
                package_type_id: values.package_type_id,
                packing_group: (this.state.booking.packing_group !== null) ? this.state.booking.packing_group : "",
                is_odex_request: this.state.sendRequestToOdex
            };
            data = {...data};
            let body = new FormData();
            for (var key in data) {
                body.append(key, data[key]);
            }
            this.setState({showDownloadPdf: true});
            if (!this.props.vgmButtonLoading && !this.props.vgmSaveButtonLoading) {
                this.props.onVgmSubmit(this.state.booking.id, data);
            }
        }
    };

    render() {
        let hours = [];
        let minutes = [];
        var d = new Date();
        var h = d.getHours();
        var m = d.getMinutes();
        for (let hr = 0; hr <= 23; hr++) {
            if (moment().diff(this.state.weighingTime, 'days') < 1) {
                if (h >= hr) {
                    hours.push(<option value={("0" + hr).slice(-2)}>{("0" + hr).slice(-2)}</option>);
                }
            } else {
                hours.push(<option value={("0" + hr).slice(-2)}>{("0" + hr).slice(-2)}</option>);
            }
        }
        for (let min = 0; min <= 59; min++) {
            if (parseInt(this.state.weighHours) === h) {
                if (m >= min) {
                    minutes.push(<option value={("0" + min).slice(-2)}>{("0" + min).slice(-2)}</option>);
                }
            } else {
                minutes.push(<option value={("0" + min).slice(-2)}>{("0" + min).slice(-2)}</option>);
            }
        }

        let user = Auth.user;

        return (this.state.booking) ? (
            <div>
                <AvForm onSubmit={this.onSubmit}>
                    <div className="container-fluid itembg">
                        <div className="div-static-data-full pop-data-type-container ">
                            <div className="row">

                                <div className="col-lg-12 col-sm-12">

                                    {this.state.selectedContainers.odex_vgm_container_status ?
                                        <div className="">
                                            <div className="container-div">
                                                Container Status
                                                : {this.state.selectedContainers.odex_vgm_container_status}
                                            </div>
                                        </div> : null
                                    }
                                    <div className="vgm-section-heding">
                                        <h1>Shipper / Booking Details</h1>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-2">
                                    <div className="static-data-input">
                                        <label>Shipping Line</label>
                                        <p>{this.state.booking.ship_line.ship_name}</p>
                                    </div>
                                </div>

                                <div className="col-lg-2 col-sm-2">
                                    <div className="static-data-input">
                                        <label>Vessel Name</label>
                                        <p>{this.state.booking.vessel}</p>
                                    </div>
                                </div>

                                <div className="col-lg-2 col-sm-2">
                                    <div className="static-data-input">
                                        <label>Voyage No.</label>
                                        <p>{this.state.booking.voyage}</p>
                                    </div>
                                </div>


                                <div className="col-lg-2 col-sm-2">
                                    <div className="static-data-input">
                                        <label>Shipment Ref No</label>
                                        <p>{this.state.booking.book_id}</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-2">
                                    <div className="static-data-input">
                                        <label>POL</label>
                                        <p>{this.state.booking.pol.port_name}</p>
                                    </div>
                                </div>

                                <div className="col-lg-2 col-sm-2">
                                    <div className="static-data-input">
                                        <label>Handover Location (FPD)</label>
                                        <p>{this.state.booking.fpd.port_name}</p>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-12">
                                    <div className="vgm-section-heding">
                                        <h1>Shipper</h1>
                                    </div>
                                </div>

                                {(user && user.company.company_type) === 2 ?
                                    <div className="col-lg-3 col-sm-3  col-12">
                                        <div className="form-group form-edit-text">
                                            <div className="form-group">
                                                <label>Shipper <span className="required">*</span></label>
                                                <Select
                                                    className="home-select"
                                                    name="shipper"
                                                    value={this.state.selectedShipper}
                                                    placeholder="Select shipper"
                                                    noOptionsMessage={() => "No record found"}
                                                    onChange={(selected) => this.handleChange('selectedShipper', 'shipperError', selected)}
                                                    options={this.props.shipperList}
                                                />
                                                <CustomFeedback>{this.state.shipperError}</CustomFeedback>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="col-lg-2 col-sm-2">
                                        <div className="static-data-input">
                                            <label>Shipper</label>
                                            <p>{user.company ? user.company.name : ""}</p>
                                        </div>
                                    </div>
                                }
                                <div className="col-lg-3 col-sm-3 col-12">
                                    <div className="form-edit-text">
                                        <AvGroup className="error-t-negative">
                                            <label>Shipper Registration/License No. <span className="required">*</span></label>
                                            <AvField name='shipper_reg_no' type="text"
                                                     className="shipper_reg_no"
                                                     customprop='shipper_reg_no'
                                                     id='shipper_reg_no'
                                                     value={this.state.booking.shipper_reg_no ? this.state.booking.shipper_reg_no : (this.state.selectedShipper ? this.state.selectedShipper.reg_no : user.company.reg_no)}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter shipper Registration/License No.'
                                                         }

                                                     }}/>
                                        </AvGroup>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-12">
                                    <div className="vgm-section-heding">
                                        <h1>Authorized Person</h1>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-12">
                                    <div className="form-edit-text">
                                        <AvGroup className="error-t-negative">
                                            <label>Authorized Person name <span className="required">*</span></label>
                                            <AvField name='authorised_person_name' type="text"
                                                     className="authorised_person_name"
                                                     customprop='authorised_person_name'
                                                     id='authorised_person_name'
                                                     value={this.state.booking.authorised_person_name}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter Authorized Person name'
                                                         }
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-12">
                                    <div className="form-edit-text">
                                        <AvGroup className="error-t-negative">
                                            <label>Designation of shipper official <span
                                                className="required">*</span></label>
                                            <AvField name='authorised_person_designation' type="text"
                                                     className="authorised_person_designation"
                                                     customprop='authorised_person_designation'
                                                     id='authorised_person_designation'
                                                     value={this.state.selectedContainers.authorised_person_designation}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter shipper Designation'
                                                         }
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                </div>
                                <AvField name='container_id' type="hidden"
                                         className="ContainerId"
                                         customprop="ContainerId"
                                         id='container_id'
                                         value={this.state.selectedContainers.id}/>

                                <div className="col-lg-3 col-sm-3 col-12">
                                    <div className="form-edit-text">
                                        <AvGroup className="error-t-negative">
                                            <label>Contact of authorised shipper official <span
                                                className="required">*</span></label>
                                            <AvField name='authorised_person_contact' type="text"
                                                     className="authorised_person_contact"
                                                     customprop="authorised_person_contact"
                                                     id='authorised_person_contact'
                                                     value={this.state.selectedContainers.authorised_person_contact}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter shipper contact'
                                                         },
                                                         number: {
                                                             errorMessage: 'Please enter Number Only'
                                                         },
                                                         minLength: {
                                                             value: 10,
                                                             errorMessage: 'Number must be 10 digits'
                                                         },
                                                         maxLength: {
                                                             value: 10,
                                                             errorMessage: 'Number must be 10 digits'
                                                         }
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-12">
                                    <div className="vgm-section-heding">
                                        <h1>Container Details</h1>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-12 col-12 ">
                                    <div className="form-edit-text vgm-bor-bottom">
                                        <AvRadioGroup className="error-l-150" name="vgm_method" id='vgm_method'
                                                      customprop="vgm_method"
                                                      value={(this.state.selectedContainers.vgm_method != null) ? (this.state.selectedContainers.vgm_method).toString() : "1"}
                                                      required
                                                      readOnly>
                                            <label className="d-block">VGM Evaluation Method</label>
                                            <AvRadio customInput label="Method 1" value="1"/>
                                            <AvRadio customInput label="Method 2" value="2"/>
                                        </AvRadioGroup>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-12">
                                    <div className="form-edit-text">
                                        <AvGroup className="error-t-negative">
                                            <label>Container no.(format eg.(CFGU7876565)) <span
                                                className="required">*</span></label>
                                            <AvField name='container_number' type="text"
                                                     className="ContainerNo"
                                                     customprop="container_number"
                                                     onKeyUp={this.handleContainerNumber}
                                                     id='container_number'
                                                     value={this.state.containerNumber}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter container No'
                                                         },
                                                         pattern: {
                                                             value: '^\s*([A-Z]{3}[J|U|Z][0-9]{7})$',
                                                             errorMessage: 'Please provide Container Number in a proper format.'
                                                         }
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-3">
                                    <div className="static-data-input">
                                        <label>Container Size</label>
                                        <p>{ formatContainer(parseInt(this.state.selectedContainers.container_type),this.state.selectedContainers.container_size) }</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-3">
                                    <div className="static-data-input">
                                        <label> Container Type</label>
                                        <p>{containerTypes[this.state.booking.commodity.container_type_id]}</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-3">
                                    <div className="static-data-input">
                                        <label>Cargo Type</label>
                                        <p>{(this.state.booking.cargo_type === 1) ? 'FCL' : 'LCL'}</p>
                                    </div>
                                </div>


                                <div className="col-lg-3 col-sm-3 col-12">
                                    <div className="form-edit-text">
                                        <label>CSC Plate Max. Wt. Limit(Gross Weight) <span
                                            className="required">*</span></label>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <AvGroup className="error-t-negative">
                                                    <AvField name='max_weight_limit' type="number"
                                                             min={1}
                                                             className="ContainerWeight"
                                                             customprop="max_weight_limit"
                                                             onChange={(event) => this.checkWeight(event, 'max_weight_limit', '')}
                                                             id='max_weight_limit'
                                                             value={this.state.selectedContainers.max_weight_limit}
                                                             validate={{
                                                                 required: {
                                                                     value: true,
                                                                     errorMessage: 'Please enter weight of container'
                                                                 }
                                                             }}
                                                    />
                                                </AvGroup>

                                            </div>
                                            <div className="col-lg-4 col-sm-4">
                                                <AvGroup className="error-t-negative">
                                                    <AvField name='max_weight_limit_unit' type="select"
                                                             className="max_weight_limit_unit"
                                                             customprop="max_weight_limit_unit"
                                                             onChange={(event) => this.checkWeight(event, 'max_weight_limit', 'max_weight_limit_unit')}
                                                             id='max_weight_limit_unit'
                                                             value="1">
                                                        <option value="1">Kgs</option>
                                                        <option value="1000">Ton</option>
                                                    </AvField>
                                                </AvGroup>

                                            </div>

                                        </div>
                                        <label
                                            className={(this.state.WeightError === null) ? "alert-wr" : "alert-wr-bk"}
                                            style={{color: "red"}}>{this.state.WeightError}</label>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-12">
                                    <div className="form-edit-text">
                                        <label>Cargo Wt(Total of cargo, packing dunnage) <span
                                            className="required">*</span></label>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <AvGroup className="error-t-negative">
                                                    <AvField name='cargo_weight' type="number"
                                                             className="cargo_weight"
                                                             min={1}
                                                             customprop="cargo_weight"
                                                             id='cargo_weight'
                                                             onChange={(event) => this.checkWeight(event, 'cargo_weight', '')}
                                                             value={this.state.selectedContainers.cargo_weight}
                                                             validate={{
                                                                 required: {
                                                                     value: true,
                                                                     errorMessage: 'Please enter Cargo Wt of container'
                                                                 }
                                                             }}
                                                    />
                                                </AvGroup>
                                            </div>

                                            <div className="col-lg-4 col-sm-4">
                                                <AvGroup className="error-t-negative">
                                                    <AvField name='cargo_weight_unit' type="select"
                                                             className="cargo_weight_unit"
                                                             customprop="cargo_weight_unit"
                                                             id='cargo_weight_unit'
                                                             onChange={(event) => this.checkWeight(event, 'cargo_weight', 'cargo_weight_unit')}
                                                             value="1">
                                                        <option value="1">Kgs</option>
                                                        <option value="1000">Ton</option>
                                                    </AvField>
                                                </AvGroup>

                                            </div>
                                        </div>
                                        <label
                                            className={(this.state.cargoWeightError === null) ? "alert-wr" : "alert-wr-bk"}
                                            style={{color: "red"}}>{this.state.cargoWeightError}</label>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-3 col-12">
                                    <div className="form-edit-text">
                                        <label>Tare Wt <span className="required">*</span></label>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <AvGroup className="error-t-negative">
                                                    <AvField name='tare_weight' type="number"
                                                             className="TareWeight"
                                                             customprop="tare_weight"
                                                             min={1}
                                                             onChange={(event) => this.checkWeight(event, 'tare_weight', '')}
                                                             id='tare_weight'
                                                             value={this.state.selectedContainers.tare_weight}
                                                             validate={{
                                                                 required: {
                                                                     value: true,
                                                                     errorMessage: 'Please enter Tare of container'
                                                                 }
                                                             }}
                                                    />
                                                </AvGroup>

                                            </div>

                                            <div className="col-lg-4 col-sm-4">
                                                <AvGroup className="error-t-negative">
                                                    <AvField name='tare_weight_unit' type="select"
                                                             className="tare_weight_unit"
                                                             customprop="tare_weight_unit"
                                                             onChange={(event) => this.checkWeight(event, 'tare_weight', 'tare_weight_unit')}
                                                             id='tare_weight_unit'
                                                             value="1">
                                                        <option value="1">Kgs</option>
                                                        <option value="1000">Ton</option>
                                                    </AvField>
                                                </AvGroup>

                                            </div>
                                        </div>
                                        <label
                                            className={(this.state.tareWeightError === null) ? "alert-wr" : "alert-wr-bk"}
                                            style={{color: "red"}}>{this.state.tareWeightError}</label>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-12">
                                    <div className="form-edit-text">
                                        <label>Verified gross mass of container</label>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <AvGroup className="error-t-negative">
                                                    <AvField name='verified_gross_mass' type="number"
                                                             className="verified_gross_mass"
                                                             customprop="verified_gross_mass"
                                                        // value={(this.state.selectedContainers != null) ? this.state.selectedContainers.verified_gross_mass : null}
                                                             value={this.state.verified_gross_mass}
                                                             id='verified_gross_mass'
                                                             //readOnly
                                                             />
                                                </AvGroup>
                                            </div>
                                            <div className="col-lg-4 col-sm-4">
                                                <AvGroup className="error-t-negative">
                                                    <AvField name='verified_gross_mass_unit' type="select"
                                                             className="verified_gross_mass_unit"
                                                             customprop="verified_gross_mass_unit"
                                                             id='verified_gross_mass_unit'
                                                             value="1"
                                                             >
                                                        <option value="1">Kgs</option>
                                                        <option value="2">Ton</option>
                                                    </AvField>
                                                </AvGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-12">
                                    <div className="form-edit-text">
                                        <AvGroup className="error-t-negative">
                                            <label>CRO no/Booking No</label>
                                            <AvField name='cro_no' type="text"
                                                     className="cro_no"
                                                     customprop='cro_no'
                                                     id='cro_no'
                                                     value={this.state.booking.cro_no}
                                                     readOnly/>
                                        </AvGroup>
                                    </div>
                                </div>


                                <div className="col-lg-3 col-sm-3 col-12">
                                    <div className="form-edit-text">
                                        <AvGroup className="error-t-negative">
                                            <label>Package Type <span className="required">*</span></label>
                                            <AvField name='package_type_id' type="select"
                                                     className="package_type_id"
                                                     customprop="package_type_id"
                                                     id='package_type_id'
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please Select Package Type'
                                                         }
                                                     }}
                                                     value={this.state.selectedContainers.package_type_id}>
                                                <option value="">Select Package Type</option>
                                                {this.state.packageType.map((pkg, key) =>
                                                    <option value={pkg.id} key={pkg.id}>{pkg.name}</option>
                                                )}
                                            </AvField>
                                        </AvGroup>
                                    </div>
                                </div>


                                <div className="col-lg-3 col-sm-3 col-12">
                                    <div className="form-edit-text">
                                        <AvGroup className="error-t-negative">
                                            <label>No. of packages <span className="required">*</span></label>
                                            <AvField name='no_of_pkgs' type="number"
                                                     className="no_of_pkgs"
                                                     min={1}
                                                     customprop="no_of_pkgs"
                                                     id='no_of_pkgs'
                                                     value={(this.state.selectedContainers != null) ? this.state.selectedContainers.no_of_pkgs : null}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter No. of packages'
                                                         }
                                                     }}
                                            />
                                        </AvGroup>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-12">
                                    <div className="vgm-section-heding">
                                        <h1>Weighbridge Details</h1>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-12">
                                    <div className="form-edit-text">
                                        <AvGroup className="error-t-negative">
                                            <label>Weighbridge registration no. <span
                                                className="required">*</span></label>
                                            <AvField name='weighbridge_reg_no' type="text"
                                                     className="weighbridge_reg_no"
                                                     customprop="weighbridge_reg_no"
                                                     id='weighbridge_reg_no'
                                                     value={(this.state.selectedContainers != null) ? this.state.selectedContainers.weighbridge_reg_no : null}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter Weighbridge registration no'
                                                         }
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-12">
                                    <AvGroup className="error-t-negative">
                                        <label>Address of Weighbridge <span className="required">*</span></label>
                                        <AvField name='weighbridge_address_1'
                                                 type="textarea" className="AddressWeighbridge"
                                                 customprop="weighbridge_address_1"
                                                 id='weighbridge_address_1'
                                                 placeholder="address line 1"
                                                 value={(this.state.selectedContainers != null) ? this.state.selectedContainers.weighbridge_address_1 : null}
                                                 validate={{
                                                     required: {
                                                         value: true,
                                                         errorMessage: 'Please enter Address of Weighbridge'
                                                     },
                                                     maxLength: {
                                                         value: 35,
                                                         errorMessage: 'You have reached your maximum limit of characters allowed'
                                                     }
                                                 }}
                                        />
                                    </AvGroup>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-12 adress-in-1">

                                    <AvGroup className="error-t-negative address-line-up">
                                        <label></label>
                                        <AvField name='weighbridge_address_2'
                                                 type="textarea" className="AddressWeighbridge"
                                                 customprop="weighbridge_address_2"
                                                 id='weighbridge_address_2'
                                                 placeholder="address line 2"
                                                 value={(this.state.selectedContainers != null) ? this.state.selectedContainers.weighbridge_address_2 : null}
                                                 validate={{
                                                     maxLength: {
                                                         value: 35,
                                                         errorMessage: 'You have reached your maximum limit of characters allowed'
                                                     }
                                                 }}
                                        />
                                    </AvGroup>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-12 adress-in-1">
                                    <AvGroup className="error-t-negative address-line-up">
                                        <label></label>
                                        <AvField name='weighbridge_address_3'
                                                 type="textarea" className="AddressWeighbridge"
                                                 customprop="weighbridge_address_3"
                                                 id='weighbridge_address_3'
                                                 placeholder="address line 3"
                                                 value={(this.state.selectedContainers != null) ? this.state.selectedContainers.weighbridge_address_3 : null}
                                                 validate={{
                                                     maxLength: {
                                                         value: 35,
                                                         errorMessage: 'You have reached your maximum limit of characters allowed'
                                                     }
                                                 }}
                                        />
                                    </AvGroup>
                                </div>

                                <div className="col-lg-3 col-sm-3 col-12">
                                    <div className="form-edit-text">
                                        <AvGroup className="error-t-negative">
                                            <label>Weighbridge Name</label>
                                            <AvField name='weighbridge_name' type="text"
                                                     className="WeighbridgeName"
                                                     customprop="weighbridge_name"
                                                     id='weighbridge_name'
                                                     value={(this.state.selectedContainers != null) ? this.state.selectedContainers.weighbridge_name : null}
                                            />
                                        </AvGroup>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-3 col-12">
                                    <div className="form-edit-text">
                                        <AvGroup className="error-t-negative">
                                            <label>Weighing slip no. <span className="required">*</span></label>
                                            <AvField name='weighbridge_slip_no' type="text"
                                                     className="SlipNo"
                                                     customprop="weighbridge_slip_no"
                                                     id='weighbridge_slip_no'
                                                     value={(this.state.selectedContainers != null) ? this.state.selectedContainers.weighbridge_slip_no : null}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter Weighing slip no'
                                                         }
                                                     }}
                                            />
                                        </AvGroup>
                                    </div>
                                </div>

                                {this.state.selectedContainers.vgm_done_at ?
                                    <div className="col-lg-3 col-sm-3 col-12">
                                        <div className="form-edit-text">
                                            <label>Date and time of Weighing<span className="required">*</span></label>
                                            <DatePicker
                                                className="date-input"
                                                selected={this.state.weighingTime}
                                                onChange={(date) => this.handleChangeDateTime(date)}
                                                maxDate={(new Date())}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={1}
                                                dateFormat="d MMMM, yyyy HH:mm aa"
                                                timeCaption="Time"
                                                onKeyDown={e => e.preventDefault()}
                                            />
                                            <CustomFeedback>{this.state.weighingTimeError}</CustomFeedback>
                                        </div>
                                    </div> :
                                    <Fragment>
                                        <div className="col-lg-3 col-sm-3 col-12">
                                            <div className="form-edit-text">
                                                <label>Date and time of Weighing<span
                                                    className="required">*</span></label>
                                                <DatePicker
                                                    className="date-input"
                                                    selected={this.state.weighingTime}
                                                    onChange={(date) => this.handleChangeDateTime(date)}
                                                    maxDate={(new Date())}
                                                    dateFormat="d MMMM, yyyy"
                                                    timeCaption="Time"
                                                    onKeyDown={e => e.preventDefault()}
                                                />
                                                <CustomFeedback>{this.state.weighingTimeError}</CustomFeedback>
                                            </div>
                                        </div>

                                        <div className="col-lg-1 col-sm-1 col-12">
                                            <label>Hours</label>
                                            <AvField name='hours' type="select"
                                                /*value={this.state.weighHours}*/
                                                     onChange={(event) => this.handleWeighTimeChange(event, 'weighHours')}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please Select minute'
                                                         }
                                                     }}>
                                                <option value="">Hours</option>
                                                {hours}
                                            </AvField>
                                        </div>
                                        <div className="col-lg-1 col-sm-1 col-12">
                                            <label>Minutes</label>
                                            <AvField name='minute' type="select"
                                                /*value={this.state.weighMinute}*/
                                                     onChange={(event) => this.handleWeighTimeChange(event, 'weighMinute')}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please Select minute'
                                                         }
                                                     }}>
                                                <option value="">Minutes</option>
                                                {minutes}
                                            </AvField>
                                        </div>
                                    </Fragment>
                                }
                                <div className="col-lg-12 col-sm-12">
                                    <div className="vgm-section-heding">
                                        <h1>Attachment Details<span className="only-file-div"> (Only PDF files accepted)</span>
                                        </h1>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <AvGroup className="error-t-negative">
                                        <label>{this.state.VGMdocLabel}</label>
                                        <div className="custom-file">
                                            <input type="file" id="vgm_doc" name="vgm_doc"
                                                   className="custom-file-input"
                                                   onChange={() => this.getFilename('vgm_doc', 'VGMdocName', 'VGMdocError', 'VGMdoc', 'VGmdocExistingFile')}/>
                                            <label className="custom-file-label"
                                                   htmlFor="vgm_doc">{this.state.VGMdocName}</label>

                                        </div>
                                        {this.state.VGmdocExistingFile}
                                        <label
                                            className={(this.state.VGMdocError !== null) ? "alert-wr-bk" : "alert-wr"}
                                            style={{color: "red"}}>{this.state.VGMdocError}</label>
                                    </AvGroup>
                                </div>

                                {/*<div className="col-lg-6 col-12">
                                    <AvGroup className="error-t-negative">
                                        <label>Authorization letter by shipper</label>
                                        <div className="custom-file">
                                            <input type="file" id="authorization_letter" name="authorization_letter"
                                                   className="custom-file-input"
                                                   onChange={() => this.getFilename('authorization_letter', 'VGMauthorizationLetterName', 'VGMauthorizationLetterError', 'VGMauthorizationLetterDoc', 'VGMauthorizationLetterExistingFile')}/>
                                            <label className="custom-file-label"
                                                   htmlFor="authorization_letter">{this.state.VGMauthorizationLetterName}</label>

                                        </div>
                                        {this.state.VGMauthorizationLetterExistingFile}
                                        <label
                                            className={(this.state.VGMauthorizationLetterError !== null) ? "alert-wr-bk" : "alert-wr"}
                                            style={{color: "red"}}>{this.state.VGMauthorizationLetterError}
                                        </label>
                                    </AvGroup>
                                </div>*/}

                                <div className="col-lg-6 col-12">
                                    <AvGroup className="error-t-negative">
                                        <label>Weight Declaration- Annexure 1</label>
                                        <div className="custom-file">
                                            <input type="file" id="weight_declaration" name="weight_declaration"
                                                   className="custom-file-input"
                                                   onChange={() => this.getFilename('weight_declaration', 'VGMweightDeclarationDocName', 'VGMweightDeclarationDocError', 'VGMweightDeclarationDoc', 'VGMweightDeclarationExistingFile')}/>
                                            <label className="custom-file-label"
                                                   htmlFor="weight_declaration">{this.state.VGMweightDeclarationDocName}</label>

                                        </div>
                                        {this.state.VGMweightDeclarationExistingFile}
                                        <label
                                            className={(this.state.VGMweightDeclarationDocError !== null) ? "alert-wr-bk" : "alert-wr"}
                                            style={{color: "red"}}>{this.state.VGMweightDeclarationDocError}</label>
                                    </AvGroup>
                                </div>


                                <div className="col-lg-12 col-sm-12">
                                    <div className="check-adox-div">
                                        {!this.state.selectedContainers.odex_vgm_container_status || this.state.selectedContainers.odex_vgm_container_status !== "VERIFIED" ?
                                            <CustomInput
                                                type="checkbox"
                                                id="is_default"
                                                label="Do you want to send request to ODEX"
                                                onChange={(event) => this.handleOdexRequest(event)}
                                                checked={this.state.sendRequestToOdex}/>

                                            :
                                            null
                                        }
                                    </div>
                                    <div className="col-lg-12 col-sm-12">
                                        <div className="full-pdf-submit-btn">
                                            <div className="pdf-dwon-btn">
                                                {this.state.showDownloadPdf ?
                                                    <ul>
                                                        <li>
                                                            <a onClick={() => this.props.downloadVgmPdf(this.state.selectedContainers, this.state.containerNumber)}><img
                                                                src={PdfIcon} alt={""}/>Download VGM PDF</a>
                                                        </li>
                                                    </ul>
                                                    : null
                                                }
                                            </div>
                                            {this.state.booking.bl_issued === 0 ?
                                                <Fragment>
                                                    <div className="vgm_sub">
                                                        <button className="btn primary-btn" id="save_button">
                                                            <ButtonLoader color="primary"
                                                                          isLoading={this.props.vgmSaveButtonLoading}
                                                                          label="Save"/>
                                                        </button>
                                                    </div>
                                                    <div className="vgm_sub">
                                                        <button className="btn primary-btn" onClick={this.submitVgm}>
                                                            <ButtonLoader color="primary"
                                                                          isLoading={this.props.vgmButtonLoading}
                                                                          label="Submit"/>
                                                        </button>
                                                    </div>
                                                </Fragment>
                                                : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AvForm>
            </div>
        ) : ""
    }
}

export default VgmForm;