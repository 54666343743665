import React, { Component, Fragment } from "react";
import Request from "../../../util/api.service";
import { API_URL } from "../../../constants/apiConstants";
import { button, Button, Col, CustomInput, Row, Container } from "reactstrap";
import {
  AvField,
  AvForm,
  AvGroup,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import Select from "react-select";
import { containerTypes, HAZARDOUS } from "../../../constants/containerTypes";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DRY, REFRIGERATED, OPENTOP, FLATRACK} from "../../../constants/containerTypes";
import collect from "collect.js";
import Auth from "../../../util/auth.service";
import ButtonLoader from "../../../util/ButtonLoader";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import TopMenu from "../TopMenu";
import Search from "../../../util/search.service";
import CustomFeedback from "../../../components/formValidations/CustomFeedback";
import { RATES_UNAVAILABLE } from "../../../constants/enquiryTypes";
import moment from "moment";
import Contact from "../../../util/contact.service";
import {
  BOOKING_PARTY,
  CHA,
  CONSIGNEE,
  FORWARDER,
  INVOICE_PARTY,
  SHIPPER,
} from "../../../constants/contactType";
import { connect } from "react-redux";
import ContactModal from "../../../components/contact/ContactModal";
import Common from "../../../util/common.service";
import { maxUploadFileSize } from "../../../constants/defaultValues";
import PdfIcon from "../../../assets/images/pdf-icon.png";
import Enquiry from "../../../util/enquiry.service";
import {isEmptyNullUndefined} from "../../../util/util.service";

class AddEnquiry extends Component {
  state = {
    isLoading: true,
    buttonLoading: false,
    modalOpen: false,
    portSelectionList: [],
    selectedPolPort: null,
    selectedService: null,
    selectedPodPort: null,
    commodity_type: null,
    commodity_types: [],
    originalCommodityList: [],
    shipperList: [],
    consigneeList: [],
    selectedShipper: null,
    selectedConsignee: null,
    selectedForwarder: null,
    selectedCha: null,
    selectedBookingParty: null,
    selectedInvoiceParty: null,
    shipperError: null,
    consigneeError: null,
    serviceError: null,
    minDate: new Date(),
    maxDate: null,
    startDate: null,
    startDateError: null,
    endDate: null,
    endDateError: null,
    etdPol: null,
    ContainerType: DRY,
    container_20ft: 0,
    container_40ft: 0,
    container_40hc: 0,
    container_40sr : 0,
    total_containers: 0,
    polPortError: null,
    podPortError: null,
    shiplineError: null,
    etdPolError: null,
    commodityError: null,
    selected_class: null,
    containerError: null,
    isDG: false,
    modalContactOpen: false,
    countryList: [],
    stateList: [],
    cityList: [],
    action: true,
    hazDoc: [],
    imo_classes: [],
    dgFiles: [],
    hazFileName:
      "Please upload MSDS, packing group , packaging certificate and HAZ declaration",
    hazFileUploading: false,
    modalLoading: false,
    polPortMessage: "Start typing port name",
    podPortMessage: "Start typing port name",
    isConsigneeMail: 0,
    isFreightCharges: 0,
    isFreightAgreed: 0,
    freightTerm: null,
    freightTermError: null,
    enquiryDetails: [],
    chaList: [],
    bookingPartyList: [],
    invoicePartyList: [],
    forwarderList: [],
    polPlaceholder:null,
    podPlaceholder:null
  };

  componentDidMount() {
    let minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);
    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 60);
    this.setState({ minDate, maxDate });
    this.getCommodity();
    this.getImoClass();
    this.getContactList();
    this.getCountryDetails();
    this.getShiplineList();
    if (this.props.match.params.id) {
      this.getEnquiryDetails();
    }
  }

  getEnquiryDetails = () => {
    this.setState({ loading: true });
    Enquiry.details(this.props.match.params.id)
      .then((response) => {
        if (response !== undefined) {
          let enquiryDetails = response.data.data;
          let selectedPolPort = {
            label:
                enquiryDetails.pol.un_code + " - " + enquiryDetails.pol.port_name,
            un_code: enquiryDetails.pol.un_code,
            value: enquiryDetails.pol.id,
            key: enquiryDetails.pol.id,
            country: enquiryDetails.pol.country.name,
          };
          let selectedPodPort = {
            label:
                enquiryDetails.pod.un_code + " - " + enquiryDetails.pod.port_name,
            un_code: enquiryDetails.pod.un_code,
            value: enquiryDetails.pod.id,
            key: enquiryDetails.pod.id,
            country: enquiryDetails.pod.country.name,
          };
          let commodity_type = {
            label: enquiryDetails.commodity.title,
            value: enquiryDetails.commodity.id,
            key: enquiryDetails.commodity.id,
            container_type_id: enquiryDetails.commodity.container_type_id,
          };
          let container_20ft = enquiryDetails.container_20ft;
          let container_40ft = enquiryDetails.container_40ft;
          let container_40hc = enquiryDetails.container_40hc;
          let container_40sr = enquiryDetails.container_40sr;
          let selectedService = {
            label: enquiryDetails.type_of_service,
            value: enquiryDetails.type_of_service,
            key: enquiryDetails.type_of_service,
          };
          let ContainerType = enquiryDetails.container_type_id;
          let selectedShipper = null;
          if (enquiryDetails.shipper) {
            selectedShipper = {
              label: enquiryDetails.shipper.company_name,
              value: enquiryDetails.shipper.id,
              key: enquiryDetails.shipper.id,
            };
          }
          let selectedConsignee = null;
          if (enquiryDetails.consignee) {
            selectedConsignee = {
              label: enquiryDetails.consignee.company_name,
              value: enquiryDetails.consignee.id,
              key: enquiryDetails.consignee.id,
            };
          }

          //   let startDate = moment(enquiryDetails.details.position_start_date).toString();
          //     let endDate =  moment(enquiryDetails.details.position_end_date).toString();
          let isDG = false;
          if (enquiryDetails.imo_class_id) {
            isDG = true;
          }
          let freightTerm = enquiryDetails.freight_terms;
          this.setState(
              {
                selectedConsignee,
                isDG,
                freightTerm,
                ContainerType,
                selectedShipper,
                container_20ft,
                container_40ft,
                container_40hc,
                container_40sr,
                selectedService,
                enquiryDetails,
                selectedPolPort,
                selectedPodPort,
                commodity_type,
              },
              this.handleCargoWeightValidation
          );
        }
      })
      .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
  };

  getCountryDetails = () => {
    let countryList = [],
      selectedCountry = null;
    Common.getAllCountry()
      .then((response) => {
        if (response !== undefined) {
          let country_list = response.data.data;
          for (let country in country_list) {
            if (country_list[country].id === 96) {
              selectedCountry = {
                label: country_list[country].name,
                value: country_list[country].id,
                key: country_list[country].id,
              };
            }

            countryList.push({
              label: country_list[country].name,
              value: country_list[country].id,
              key: country_list[country].id,
            });
          }
          this.setState(
              {countryList, country: selectedCountry},
              this.getStateDetails
          );
        }
      })
      .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
  };

  getStateDetails = () => {
    let stateList = [];
    Common.stateByCountry(this.state.country.value).then((response) => {
      if (response !== undefined) {
        let state_list = response.data.data;
        for (let state in state_list) {
          stateList.push({
            label: state_list[state].name,
            value: state_list[state].id,
            key: state_list[state].id,
          });
        }
        this.setState({stateList});
      }
    });
  };

  getCommodity = () => {
    Request.get(`${API_URL}master/commodity-type`)
      .then((response) => {
        if (response !== undefined) {
          if (response.data.status === "success") {
            let commodity_types = [];
            let filteredCommodity = collect(response.data.data);
            filteredCommodity = filteredCommodity.where(
                "container_type_id",
                this.state.ContainerType
            );

            filteredCommodity = filteredCommodity.toArray();
            for (let commodity_type of filteredCommodity) {
              commodity_types.push({
                label: commodity_type.title,
                value: commodity_type.id,
                key: commodity_type.id,
                container_type_id: commodity_type.container_type_id,
              });
            }
            this.setState({
              commodity_types,
              originalCommodityList: response.data.data,
            });
          }
        }
      })
      .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
  };

  getImoClass = () => {
    Request.get(`${API_URL}master/imo-class`)
      .then((response) => {
        if (response !== undefined) {
          let imo_classes = response.data.data;
          this.setState({imo_classes});
        }
      })
      .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
  };

  getContactList = () => {
    if (this.props.auth.is_authenticated) {
      Contact.get()
        .then((response) => {
          if (response !== undefined) {
            let shipperList = [];
            let consigneeList = [];
            let chaList = [];
            let bookingPartyList = [];
            let invoicePartyList = [];
            let forwarderList = [];

            for (let contact of response.data.data) {
              if (contact.type === SHIPPER) {
                shipperList.push({
                  label: contact.company_name,
                  value: contact.id,
                  key: contact.id,
                });
              }
              if (contact.type === CONSIGNEE) {
                consigneeList.push({
                  label: contact.company_name,
                  value: contact.id,
                  key: contact.id,
                });
              }
              if (contact.type === CHA) {
                chaList.push({
                  label: contact.company_name,
                  value: contact.id,
                  key: contact.id,
                });
              }
              if (contact.type === BOOKING_PARTY || contact.type === SHIPPER) {
                bookingPartyList.push({
                  label: contact.company_name,
                  value: contact.id,
                  key: contact.id,
                });
              }
              if (contact.type === FORWARDER) {
                forwarderList.push({
                  label: contact.company_name,
                  value: contact.id,
                  key: contact.id,
                });
              }
              if (
                  contact.type === INVOICE_PARTY ||
                  contact.type === BOOKING_PARTY ||
                  contact.type === SHIPPER
              ) {
                invoicePartyList.push({
                  label: contact.company_name,
                  value: contact.id,
                  key: contact.id,
                });
              }
            }
            this.setState({
              shipperList,
              consigneeList,
              chaList,
              bookingPartyList,
              forwarderList,
              invoicePartyList,
            });
          }
        })
        .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    }
  };

  getShiplineList = () => {
    let shiplineList = [];
    Request.get(API_URL + "master/ship-line")
      .then((response) => {
        if (response !== undefined) {
          if (response.data.data) {
            for (let shipline of response.data.data) {
              shiplineList.push({
                label: shipline.ship_name,
                value: shipline.id,
                key: shipline.id,
              });
            }
          }
        }
      })
      .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    this.setState({ shiplineList, isLoading: false });
  };

  handleContainerTypeChange = (event) => {
    let commodity_types = [];
    let filteredCommodity = collect(this.state.originalCommodityList);
    filteredCommodity = filteredCommodity.where(
      "container_type_id",
      parseInt(event.target.value)
    );
    filteredCommodity = filteredCommodity.toArray();

    for (let commodity_type of filteredCommodity) {
      commodity_types.push({
        label: commodity_type.title,
        value: commodity_type.id,
        key: commodity_type.id,
        container_type_id: commodity_type.container_type_id,
      });
    }

    let isDG = parseInt(event.target.value) === HAZARDOUS;

    let isContainerRefrigerated;
    isContainerRefrigerated = parseInt(event.target.value) === REFRIGERATED;

    let container_40ft = parseInt(event.target.value) === REFRIGERATED ? 0 : this.state.container_40ft;
    let container_40sr = parseInt(event.target.value) != FLATRACK ? 0 : this.state.container_40sr;  
    this.setState(
      {
        ContainerType: parseInt(event.target.value),
        container_40ft,
        container_40sr,
        isDG,
        commodity_types,
        commodity_type: null,
        isContainerRefrigerated,
      },
      this.handleCargoWeightValidation
    );
  };

  handlePortInputChange = (searchString, portMessage, polPlaceholder) => {
    if (searchString.length >= 3) {
    this.setState({[polPlaceholder]: 'Loading....'});
      searchString = searchString.replace(/\s\s+/g, " ");
      Search.searchPort(searchString)
        .then((response) => {
          if (response !== undefined) {
            let port_list = response.data.data;
            let portSelectionList = port_list.map((port) => {
              return {
                label: port.un_code + " - " + port.port_name,
                un_code: port.un_code,
                value: port.id,
                key: port.id,
                country: port.country.name,
              };
            });

          if (!response.data.data.length && searchString.length > 3) {
            this.setState({ [portMessage]: "No result found",
              [polPlaceholder]: (port_list.length === 0 ? 'No record found' : '') });
          } else {
            this.setState({ portSelectionList, [polPlaceholder]: 'No record found' });
          }
        }
        })
        .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    }
  };

  handleChange = (name, error, selected) => {
    let errorName = null;
    this.setState({ [name]: selected, [error]: errorName });
  };

  handleDateChange = (date, name, errorName) => {
    if (
      (name === "startDate" && this.state.endDate) ||
      (name === "endDate" && this.state.startDate)
    ) {
      let endDate = this.state.endDate;
      let startDate = this.state.startDate;
      if (name === "startDate") {
        if (moment(date).isAfter(this.state.endDate)) {
          endDate = null;
        }
        this.setState({ [name]: date, [errorName]: null, endDate });
      }
      if (name === "endDate") {
        if (moment(this.state.startDate).isAfter(date)) {
          startDate = null;
        }
        this.setState({ [name]: date, [errorName]: null, startDate });
      }
    } else {
      this.setState({ [name]: date, [errorName]: null });
    }
  };

  handleDgChange = (event) => {
    let isDG = false;
    let ContainerType = this.state.ContainerType;
    if (!event.target.checked && this.state.ContainerType === HAZARDOUS) {
      ContainerType = DRY;
    }
    isDG = !!event.target.checked;
    let commodity_types = this.state.commodity_types;
    let commodity_type = this.state.commodity_type;
    if (!event.target.checked && this.state.ContainerType === HAZARDOUS) {
      commodity_types = [];
      commodity_type = null;
      let filteredCommodity = collect(this.state.originalCommodityList);
      filteredCommodity = filteredCommodity.where("container_type_id", DRY);
      filteredCommodity = filteredCommodity.toArray();

      for (let commodity_type of filteredCommodity) {
        commodity_types.push({
          label: commodity_type.title,
          value: commodity_type.id,
          key: commodity_type.id,
          container_type_id: commodity_type.container_type_id,
        });
      }
    }
    this.setState({ commodity_types, commodity_type, isDG, ContainerType });
  };

  increment = (size) => {
    let container_count = this.state["container_" + size];
    let total_containers = this.state.total_containers;
    total_containers++;
    this.setState(
      {
        ["container_" + size]: ++container_count,
        total_containers,
      },
      this.handleCargoWeightValidation
    );
  };

  decrement = (size) => {
    let container_count = this.state["container_" + size];
    let total_containers = this.state.total_containers;
    if (container_count > 0) {
      total_containers--;
      this.setState(
        {
          ["container_" + size]: --container_count,
          total_containers,
        },
        this.handleCargoWeightValidation
      );
    }
  };

  handleCargoWeightValidation = () => {
    let cargo_weight_20, cargo_weight_40, cargo_weight_40hc, cargo_weight_40sr;
    if (this.state.container_20ft > 0) {
      if (this.state.ContainerType === DRY) {
        cargo_weight_20 = 26000;
      } else if (this.state.ContainerType === REFRIGERATED) {
        cargo_weight_20 = 22000;
      } else if(this.state.ContainerType == OPENTOP){
        cargo_weight_20 = 28000;
      } else if(this.state.ContainerType == FLATRACK){
        cargo_weight_20 = 31000;
      } else {
        cargo_weight_20 = 26000;
      }
    }

    if (this.state.container_40ft > 0) {
      if (this.state.ContainerType === DRY) {
        cargo_weight_40 = 26500;
      } else if(this.state.ContainerType == OPENTOP){
        cargo_weight_40hc = 28700 ;
      } else if(this.state.ContainerType == FLATRACK){
        cargo_weight_40hc = 40100 ;
      } else {
        cargo_weight_40 = 26500;
      }
    }

    if (this.state.container_40hc > 0) {
      if (this.state.ContainerType === DRY) {
        cargo_weight_40hc = 28000;
      } else if (this.state.ContainerType === REFRIGERATED) {
        cargo_weight_40hc = 29000;
      } else if(this.state.ContainerType == OPENTOP){
        cargo_weight_40hc = 28700;
      } else if(this.state.ContainerType == FLATRACK){
        cargo_weight_40hc = 40100;
      } else {
        cargo_weight_40hc = 28000;
      }
    }

    if (this.state.container_40sr > 0) {
      cargo_weight_40sr = 44900;
    }

    this.setState({ cargo_weight_20, cargo_weight_40, cargo_weight_40hc,cargo_weight_40sr });
  };

  onSubmit = (event, errors, values) => {
    let polPortError = null,
      podPortError = null,
      shiplineError = null,
      etdPolError = null,
      commodityError = null,
      containerError = null,
      shipperError = null,
      startDateError = null,
      endDateError = null,
      serviceError = null,
      freightTermError = null,
      bookingPartyError = null,
      invoicePartyError = null;

    let user = Auth.user;

    if (!this.state.selectedPodPort) {
      podPortError = "Please select POD Port.";
      errors.push(podPortError);
    }
    if (!this.state.selectedPolPort) {
      polPortError = "Please select POL Port.";
      errors.push(polPortError);
    }

    if (!this.state.commodity_type) {
      commodityError = "Please select Commodity.";
      errors.push(commodityError);
    }
    if (
      this.state.container_20ft +
        this.state.container_40ft +
        this.state.container_40hc +
        this.state.container_40sr <=
      0
    ) {
      containerError = "Please Select At least one Container";
      errors.push(containerError);
    } else if (
      this.state.selectedPolPort &&
      this.state.selectedPodPort &&
      this.state.selectedPolPort.value === this.state.selectedPodPort.value
    ) {
      containerError = "Port of loading and Port of discharge cannot be same";
      errors.push(containerError);
    }

    /*if (!this.state.selectedShipper && user.company.company_type === 2) {
            shipperError = "Please select Exporter";
            errors.push(shipperError);
        }*/

    if (!this.state.selectedBookingParty) {
      bookingPartyError = "Please Select Booking Party";
      errors.push(bookingPartyError);
    }

    if (!this.state.selectedInvoiceParty) {
      invoicePartyError = "Please Select Invoice Party";
      errors.push(invoicePartyError);
    }

    if (!this.state.startDate) {
      startDateError = "Please select position start Date";
      errors.push(startDateError);
    }
    if (!this.state.endDate) {
      endDateError = "Please select position end Date";
      errors.push(endDateError);
    }

    if (!this.state.selectedService) {
      serviceError = "Please select service";
      errors.push(serviceError);
    }

    if (values.freight_terms === "undefined") {
      freightTermError = "Please select freight term";
      errors.push(freightTermError);
    }

    this.setState({
      podPortError,
      polPortError,
      shiplineError,
      etdPolError,
      commodityError,
      containerError,
      shipperError,
      startDateError,
      endDateError,
      serviceError,
      freightTermError,
      bookingPartyError,
      invoicePartyError,
    });

    if (errors.length === 0 && !this.state.buttonLoading) {
      let user = Auth.user;
      this.setState({ buttonLoading: true });

      let data = {
        ...values,
        is_dg: this.state.isDG,
        pol: this.state.selectedPolPort ? this.state.selectedPolPort.value : "",
        pod: this.state.selectedPodPort ? this.state.selectedPodPort.value : "",
        position_start_date: this.state.startDate
          ? moment(this.state.startDate).format("YYYY-MM-DD HH:mm:ss")
          : "",
        position_end_date: this.state.endDate
          ? moment(this.state.endDate).format("YYYY-MM-DD HH:mm:ss")
          : "",
        container_20ft: this.state.container_20ft,
        container_40ft: this.state.container_40ft,
        container_40hc: this.state.container_40hc,
        container_40sr: this.state.container_40sr,
        total_containers:
          parseInt(this.state.container_20ft) +
          parseInt(this.state.container_40ft) +
          parseInt(this.state.container_40hc) + 
          parseInt(this.state.container_40sr) ,
        container_type_id: this.state.ContainerType,
        commodity_type_id: this.state.commodity_type
          ? this.state.commodity_type.value
          : "",
        etd_pol: this.state.etdPol
          ? moment(this.state.etdPol).format("YYYY-MM-DD")
          : "",
        user_id: user.id,
        mobile: user.mobile,
        email: user.email,
        type_of_service: this.state.selectedService
          ? this.state.selectedService.value
          : "",
        freight_terms:
          typeof values.freight_terms !== "undefined"
            ? values.freight_terms
            : "",
        shipper_id: this.state.selectedShipper
          ? this.state.selectedShipper.value
          : "",
        enquiry_type: RATES_UNAVAILABLE,
        customer_name: user.first_name + " " + user.last_name,
        user_company_id: user.company.id,
        isConsigneeMail: this.state.isConsigneeMail,
        consignee_id: this.state.selectedConsignee
          ? this.state.selectedConsignee.value
          : "",
        cha_id: this.state.selectedCha ? this.state.selectedCha.value : "",
        forwarder_id: this.state.selectedForwarder
          ? this.state.selectedForwarder.value
          : "",
        booking_party_id: this.state.selectedBookingParty
          ? this.state.selectedBookingParty.value
          : "",
        invoice_party_id: this.state.selectedInvoiceParty
          ? this.state.selectedInvoiceParty.value
          : "",
        is_freight_agreed: this.state.isFreightAgreed,
        is_freight_charges: this.state.isFreightCharges,
      };

      if (this.state.hazDoc && this.state.isDG) {
        for (let file in this.state.hazDoc) {
          data["haz_file_" + file] = this.state.hazDoc[file];
        }
      }

      Request.post(API_URL + "/user/enquiry", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (response.data.status === "success") {
            NotificationManager.success(
              "Success message",
              response.data.message
            );
            this.props.history.push(
              "/enquiry/" + response.data.enquiry_id + "/view"
            );
          } else {
            NotificationManager.error("Error message", response.data.message);
          }
        })
        .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    }else{
      var error_element = document.getElementsByClassName('invalid-feedback');
      error_element[0].parentNode.parentNode.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  toggleContactModal = (value) => {
    this.setState({
      modalContactOpen: !this.state.modalContactOpen,
      selectedContactType: value,
    });
    this.getContactList();
  };

  handleFreightChange = (event) => {
    this.setState({ freightTerm: parseInt(event.target.value) });
    this.setState({ freightTermError: "" });
  };

  addAddress = (data) => {
    this.setState({ modalLoading: true });
    Contact.add(data)
      .then((response) => {
        if (response.data.status === "success") {
          let selectedContact = null;
          if (response.data.data[0].type) {
            selectedContact = {
              label: response.data.data[0].company_name.toUpperCase(),
              value: response.data.data[0].id,
              key: response.data.data[0].id,
              address: response.data.data[0].address.toUpperCase(),
              mobile: response.data.data[0].mobile,
              email: response.data.data[0].email,
            };
            let contactList = [];
            for (let contacts of response.data.data) {
              contactList.push({
                label: contacts.company_name,
                value: contacts.id,
                key: contacts.id,
                address: contacts.address,
                email: contacts.email,
                mobile: contacts.mobile,
              });
            }
            let selectedShipper = this.state.selectedShipper;
            let selectedConsignee = this.state.selectedConsignee;
            let selectedCha = this.state.selectedCha;
            let selectedForwarder = this.state.selectedForwarder;
            let selectedBookingParty = this.state.selectedBookingParty;
            let selectedInvoiceParty = this.state.selectedInvoiceParty;
            let shipperList = this.state.shipperList;
            let consigneeList = this.state.consigneeList;
            let chaList = this.state.chaList;
            let bookingPartyList = this.state.bookingPartyList;
            let invoicePartyList = this.state.invoicePartyList;
            let forwarderList = this.state.forwarderList;
            if (response.data.data[0].type === SHIPPER) {
              selectedShipper = selectedContact;
              shipperList = contactList;
            }
            if (response.data.data[0].type === CONSIGNEE) {
              selectedConsignee = selectedContact;
              consigneeList = contactList;
            }
            if (response.data.data[0].type === CHA) {
              selectedCha = selectedContact;
              chaList = contactList;
            }
            if (
              response.data.data[0].type === BOOKING_PARTY ||
              response.data.data[0].type === SHIPPER
            ) {
              selectedBookingParty = selectedContact;
              bookingPartyList = contactList;
            }
            if (
              response.data.data[0].type === INVOICE_PARTY ||
              response.data.data[0].type === SHIPPER
            ) {
              selectedInvoiceParty = selectedContact;
              invoicePartyList = contactList;
            }
            if (response.data.data[0].type === FORWARDER) {
              selectedForwarder = selectedContact;
              forwarderList = contactList;
            }

            this.setState({
              shipperList,
              consigneeList,
              selectedShipper,
              selectedConsignee,
              chaList,
              bookingPartyList,
              invoicePartyList,
              forwarderList,
              selectedCha,
              selectedForwarder,
              //selectedBookingParty,
              //selectedInvoiceParty,
            });
          }
          this.setState({
            modalContactOpen: !this.state.modalContactOpen,
            modalLoading: false,
          });
        }
      })
      .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
  };

  uploadHazFiles = () => {
    var imagefile = document.querySelector("#haz_files");
    let dgFiles = this.state.dgFiles;
    var hazDoc = this.state.hazDoc;
    let allowed_extensions = [
      "pdf",
      "jpeg",
      "jpg",
      "png",
      "xlsx",
      "doc",
      "docx",
    ];

    for (let fileKey in imagefile.files) {
      let file = imagefile.files[fileKey];
      if (file && file.name) {
        var parts = file.name.split(".");
        if (
          allowed_extensions.indexOf(parts[parts.length - 1].toLowerCase()) !==
          -1
        ) {
          if (file.size <= maxUploadFileSize) {
            hazDoc.push(file);
            dgFiles.push(
              <li>
                <a href="" target="_blank" rel="noopener noreferrer">
                  {/*<img src={PdfIcon} alt={""}/>*/}
                  {file.name}
                </a>
                <i
                  onClick={() => this.deleteDgFiles(fileKey)}
                  className="fa fa-times color-changes"
                />
              </li>
            );
          }
        }
      }
    }
    this.setState({ hazDoc, dgFiles });
  };

  handleApproval = (event, name) => {
    if (event.target.checked === false) {
      this.setState({ [name]: 0 });
    } else {
      this.setState({ [name]: 1 });
    }
  };

  deleteDgFiles = (key) => {
    let dgFiles = [];
    var hazDoc = [];
    for (let doc in this.state.hazDoc) {
      if (doc !== key) {
        hazDoc.push(this.state.hazDoc[doc]);
      }
    }
    for (let file in hazDoc) {
      dgFiles.push(
        <li>
          <a href="" target="_blank" rel="noopener noreferrer">
            <img src={PdfIcon} alt={""} />
            {hazDoc[file].name}
          </a>
          <i
            onClick={() => this.deleteDgFiles(file)}
            className="fa fa-times color-changes"
          />
        </li>
      );
    }
    this.setState({ hazDoc, dgFiles });
  };

  render() {
    let containerType = [];
    for (let type in containerTypes) {
      containerType.push(<option value={type}>{containerTypes[type]}</option>);
    }

    let user = Auth.user;

    return !this.state.isLoading ? (
      <Fragment>
        <TopMenu />
        <div className="new-change-confirm-booking-full">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="commodities-div">
                <p>
                  Commodities may need special handling and approvals are -Soya,
                  Raw Cotton, Batteries, Tobacco, Incense and Match Sticks,
                  Oils, Toys, Scrap, Personal Effects{" "}
                </p>
              </div>
            </div>
            <div className="center-div-col col-sm-12 col-lg-9 col-xl-9">
              <div className="new-change-confirm-booking">
                <AvForm onSubmit={this.onSubmit}>
                  <div className="row">
                    <div className="col-lg-3 col-sm-3">
                      <label>Container Type</label>
                      <span className="required">*</span>
                      <AvField
                        type="select"
                        onChange={this.handleContainerTypeChange}
                        name="container_type"
                        value={this.state.ContainerType}
                      >
                        {containerType}
                      </AvField>
                    </div>
                    <div className="col-lg-3 col-sm-3">
                      <label>POL</label>
                      <span className="required">*</span>
                      <Select
                        className="local-charges-select"
                        value={this.state.selectedPolPort}
                        placeholder={!isEmptyNullUndefined(this.state.polPlaceholder) ? this.state.polPlaceholder : "Search POL"}
                        noOptionsMessage={() => this.state.polPortMessage}
                        onChange={(selectedPort) =>
                          this.handleChange(
                            "selectedPolPort",
                            "polPortError",
                            selectedPort
                          )
                        }
                        options={this.state.portSelectionList}
                        onInputChange={(searchString) =>
                          this.handlePortInputChange(searchString,"polPortMessage",'polPlaceholder')
                        }
                      />
                      <CustomFeedback>{this.state.polPortError}</CustomFeedback>
                    </div>
                    <div className="col-lg-3 col-sm-3">
                      <label>POD</label>
                      <span className="required">*</span>
                      <Select
                        className="local-charges-select"
                        value={this.state.selectedPodPort}
                        placeholder={!isEmptyNullUndefined(this.state.podPlaceholder) ? this.state.podPlaceholder : "Search POD"}
                        noOptionsMessage={() => this.state.podPortMessage}
                        onChange={(selected) =>
                          this.handleChange(
                            "selectedPodPort",
                            "podPortError",
                            selected
                          )
                        }
                        options={this.state.portSelectionList}
                        onInputChange={(searchString) =>
                          this.handlePortInputChange(searchString,"podPortMessage", 'podPlaceholder')
                        }
                      />
                      <CustomFeedback>{this.state.podPortError}</CustomFeedback>
                    </div>
                    <div className="col-lg-3 col-sm-3">
                      <label>Commodity</label>
                      <span className="required">*</span>
                      <Select
                        className="home-select"
                        value={this.state.commodity_type}
                        maxMenuHeight={110}
                        name="commodity_type"
                        isDisabled={
                          this.state.ContainerType &&
                          this.state.ContainerType === 3
                        }
                        onChange={(selected) =>
                          this.handleChange(
                            "commodity_type",
                            "commodityError",
                            selected
                          )
                        }
                        options={this.state.commodity_types}
                        noOptionsMessage={() => "No record found"}
                        placeholder="Commodity"
                      />
                      <CustomFeedback>
                        {this.state.commodityError}
                      </CustomFeedback>
                    </div>
                  </div>

                  <Row>
                    <Col lg="12" sm="12">
                      <div className="confirm-booking-new-table">
                        <table>
                          <thead>
                            <tr>
                              <th>Container</th>
                              <th>Qty</th>
                              <th>Cargo Weight per Container ( kg )</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                              {this.state.ContainerType == REFRIGERATED ? "20 RF" : this.state.ContainerType == OPENTOP ? "20 OT" : this.state.ContainerType == FLATRACK ?  "20 FR" : "20 FT"}
                              </td>
                              <td>
                                <div className="counter-bg">
                                  <span className="input-group-btn">
                                    <button
                                      type="button"
                                      onClick={() => this.decrement("20ft")}
                                      className="btn btn-default btn-number"
                                    >
                                      <i className="fa fa-minus" />
                                    </button>
                                  </span>
                                  <input
                                    type="text"
                                    name="quant[1]"
                                    className="form-control input-number"
                                    max="10"
                                    value={this.state.container_20ft}
                                  />
                                  <span className="input-group-btn">
                                    <button
                                      type="button"
                                      onClick={() => this.increment("20ft")}
                                      className="btn btn-default btn-number"
                                    >
                                      <i className="fa fa-plus" />
                                    </button>
                                  </span>
                                </div>
                              </td>
                              <td>
                                <AvField
                                  type="text"
                                  name="cargo_weight_20"
                                  readOnly={this.state.container_20ft <= 0}
                                  placeholder="Cargo Weight"
                                  value={
                                    this.state.container_20ft <= 0
                                      ? ""
                                      : this.state.enquiryDetails &&
                                        this.state.enquiryDetails
                                          .cargo_weight &&
                                        this.state.enquiryDetails.cargo_weight[
                                          "20"
                                        ]
                                      ? this.state.enquiryDetails.cargo_weight[
                                          "20"
                                        ]
                                      : ""
                                  }
                                  validate={{
                                    required: {
                                      value: this.state.container_20ft > 0,
                                      errorMessage: "Please enter Cargo Weight",
                                    },
                                    min: {
                                      value: 1,
                                      errorMessage:
                                        "Cargo Weight should not be less.",
                                    },
                                    max: {
                                      value:
                                        this.state.container_20ft <= 0
                                          ? ""
                                          : parseInt(
                                              this.state.cargo_weight_20
                                            ),
                                      errorMessage:
                                        this.state.container_20ft <= 0
                                          ? ""
                                          : "Max cargo weight limit allowed is " +
                                            this.state.cargo_weight_20,
                                    },
                                  }}
                                />
                              </td>
                            </tr>
                            {this.state.ContainerType !== REFRIGERATED ? (
                              <tr>
                                <td>{this.state.ContainerType == OPENTOP ? "40 OT" : this.state.ContainerType == FLATRACK ?  "40 FR (Collapsible)": "40 FT"}</td>
                                <td>
                                  <div className="counter-bg">
                                    <span className="input-group-btn">
                                      <button
                                        type="button"
                                        onClick={() => this.decrement("40ft")}
                                        className="btn btn-default btn-number"
                                      >
                                        <i className="fa fa-minus" />
                                      </button>
                                    </span>
                                    <input
                                      type="text"
                                      name="quant[1]"
                                      className="form-control input-number"
                                      max="10"
                                      value={this.state.container_40ft}
                                    />
                                    <span className="input-group-btn">
                                      <button
                                        type="button"
                                        onClick={() => this.increment("40ft")}
                                        className="btn btn-default btn-number"
                                      >
                                        <i className="fa fa-plus" />
                                      </button>
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <AvField
                                    type="text"
                                    name="cargo_weight_40"
                                    placeholder="Cargo Weight"
                                    value={
                                      this.state.container_40ft <= 0
                                        ? ""
                                        : this.state.enquiryDetails &&
                                          this.state.enquiryDetails
                                            .cargo_weight &&
                                          this.state.enquiryDetails
                                            .cargo_weight["40"]
                                        ? this.state.enquiryDetails
                                            .cargo_weight["40"]
                                        : ""
                                    }
                                    readOnly={this.state.container_40ft <= 0}
                                    validate={{
                                      required: {
                                        value: this.state.container_40ft > 0,
                                        errorMessage:
                                          "Please enter Cargo Weight",
                                      },

                                      min: {
                                        value: 1,
                                        errorMessage:
                                          "Cargo Weight should not be less.",
                                      },
                                      max: {
                                        value:
                                          this.state.container_40ft <= 0
                                            ? ""
                                            : parseInt(
                                                this.state.cargo_weight_40
                                              ),
                                        errorMessage:
                                          this.state.container_40ft <= 0
                                            ? ""
                                            : "Max cargo weight limit allowed is " +
                                              this.state.cargo_weight_40,
                                      },
                                    }}
                                  />
                                </td>
                              </tr>
                            ) : null}
                            <tr>
                              <td>
                              {this.state.ContainerType == REFRIGERATED ? "40 RF HC": this.state.ContainerType == OPENTOP ? "40 OT HC" : this.state.ContainerType == FLATRACK ?  "40 FL HC" : "40 HC"}
                              </td>
                              <td>
                                <div className="counter-bg">
                                  <span className="input-group-btn">
                                    <button
                                      type="button"
                                      onClick={() => this.decrement("40hc")}
                                      className="btn btn-default btn-number"
                                    >
                                      <i className="fa fa-minus" />
                                    </button>
                                  </span>
                                  <input
                                    type="text"
                                    name="quant[1]"
                                    className="form-control input-number"
                                    max="10"
                                    value={this.state.container_40hc}
                                  />
                                  <span className="input-group-btn">
                                    <button
                                      type="button"
                                      onClick={() => this.increment("40hc")}
                                      className="btn btn-default btn-number"
                                    >
                                      <i className="fa fa-plus" />
                                    </button>
                                  </span>
                                </div>
                              </td>
                              <td>
                                <AvField
                                  type="text"
                                  name="cargo_weight_40hc"
                                  value={
                                    this.state.container_40hc <= 0
                                      ? ""
                                      : this.state.enquiryDetails &&
                                        this.state.enquiryDetails
                                          .cargo_weight &&
                                        this.state.enquiryDetails.cargo_weight[
                                          "40hc"
                                        ]
                                      ? this.state.enquiryDetails.cargo_weight[
                                          "40hc"
                                        ]
                                      : ""
                                  }
                                  placeholder="Cargo Weight"
                                  readOnly={this.state.container_40hc <= 0}
                                  validate={{
                                    required: {
                                      value: this.state.container_40hc > 0,
                                      errorMessage: "Please enter Cargo Weight",
                                    },
                                    min: {
                                      value: 1,
                                      errorMessage:
                                        "Cargo Weight should not be less.",
                                    },
                                    max: {
                                      value:
                                        this.state.container_40hc <= 0
                                          ? ""
                                          : parseInt(
                                              this.state.cargo_weight_40hc
                                            ) ,
                                      errorMessage:
                                        this.state.container_40hc <= 0
                                          ? ""
                                          : "Max cargo weight limit allowed is " +
                                            this.state.cargo_weight_40hc,
                                    },
                                  }}
                                />
                              </td>
                            </tr>
                            {this.state.ContainerType == FLATRACK ? 
                              <tr>
                              <td>
                                40 SR
                              </td>
                              <td>
                                <div className="counter-bg">
                                  <span className="input-group-btn">
                                    <button
                                      type="button"
                                      onClick={() => this.decrement("40sr")}
                                      className="btn btn-default btn-number"
                                    >
                                      <i className="fa fa-minus" />
                                    </button>
                                  </span>
                                  <input
                                    type="text"
                                    name="quant[1]"
                                    className="form-control input-number"
                                    max="10"
                                    value={this.state.container_40sr}
                                  />
                                  <span className="input-group-btn">
                                    <button
                                      type="button"
                                      onClick={() => this.increment("40sr")}
                                      className="btn btn-default btn-number"
                                    >
                                      <i className="fa fa-plus" />
                                    </button>
                                  </span>
                                </div>
                              </td>
                              <td>
                                <AvField
                                  type="text"
                                  name="cargo_weight_40sr"
                                  value={
                                    this.state.container_40sr <= 0
                                      ? ""
                                      : this.state.enquiryDetails &&
                                        this.state.enquiryDetails
                                          .cargo_weight &&
                                        this.state.enquiryDetails.cargo_weight[
                                          "40sr"
                                        ]
                                      ? this.state.enquiryDetails.cargo_weight[
                                          "40sr"
                                        ]
                                      : ""
                                  }
                                  placeholder="Cargo Weight"
                                  readOnly={this.state.container_40sr <= 0}
                                  validate={{
                                    required: {
                                      value: this.state.container_40sr > 0,
                                      errorMessage: "Please enter Cargo Weight",
                                    },
                                    min: {
                                      value: 1,
                                      errorMessage:
                                        "Cargo Weight should not be less.",
                                    },
                                    max: {
                                      value:
                                        this.state.container_40sr <= 0 ? "" : parseInt(this.state.cargo_weight_40sr),
                                      errorMessage: this.state.container_40sr <= 0 ? "" : "Max cargo weight limit allowed is " + parseInt(this.state.cargo_weight_40sr) ,
                                    },
                                  }}
                                />
                              </td>
                            </tr>
                            : null }
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>

                  <div className="row">
                    <div className="col-6">
                      {this.state.ContainerType !== REFRIGERATED ? (
                        <div className="new-cargo-contains-hazardous">
                          <CustomInput
                            type="checkbox"
                            id={"is_dg"}
                            onChange={(event) => this.handleDgChange(event)}
                            checked={this.state.isDG}
                          />
                          <label className="container-lable">
                            Cargo Contains Hazardous / Dangerous Goods
                          </label>
                        </div>
                      ) : (
                        <div className="new-cargo-contains-hazardous">
                          <label className="container-lable">
                            Cargo is Reefer Commodity
                          </label>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-4 col-sm-4 text-box">
                      <AvGroup>
                        <label>Special Remarks</label>
                        <AvField
                          name="dry_haz_remark"
                          id="dry_haz_remark"
                          type="text"
                          placeholder="Special Remarks"
                          className="text-touppercase"
                          value={
                            this.state.enquiryDetails
                              ? this.state.enquiryDetails.odo_remark
                              : ""
                          }
                          validate={{
                            maxLength: {
                              value: 250,
                              errorMessage:
                                "You have reached your maximum limit of characters allowed",
                            },
                          }}
                        />
                      </AvGroup>
                    </div>
                  </div>

                  {this.state.isDG ? (
                    <div className="col-lg-12 col-sm-12">
                      <div className="upload-2-files hazardous-uplod-file">
                        <div className="upload-btn-wrapper">
                          <button className="btn">
                            <i className="fa fa-file-alt" /> Select File
                          </button>
                          <p>
                            Please upload MSDS, Packing group, Packaging
                            certificate and HAZ declaration
                          </p>
                          <input
                            type="file"
                            name="haz_files"
                            id="haz_files"
                            onChange={this.uploadHazFiles}
                            multiple
                          />
                        </div>
                      </div>
                      <div className="vgm-div-box-right vgm-div-box-hazardous-uplod-file">
                        <ul>{this.state.dgFiles}</ul>
                      </div>
                    </div>
                  ) : null}

                  {this.state.isDG &&
                  this.state.ContainerType !== REFRIGERATED ? (
                    <div className="row">
                      <div className="col-lg-3 col-sm-3 col-12">
                        <AvGroup>
                          <label>Packing Group</label>
                          <span className="required">*</span>
                          <AvField
                            type="select"
                            name="packing_group"
                            value={
                              this.state.enquiryDetails
                                ? this.state.enquiryDetails.packing_group
                                : ""
                            }
                            id="packing_group"
                            validate={{
                              required: {
                                value: this.state.isDG,
                                errorMessage: "Please select Packing Group",
                              },
                            }}
                          >
                            <option value="">Select Packing Group</option>
                            <option value="1">Packing Group I</option>
                            <option value="2">Packing Group II</option>
                            <option value="3">Packing Group III</option>
                          </AvField>
                        </AvGroup>
                      </div>

                      <div className="col-lg-3 col-sm-3 col-12">
                        <AvGroup>
                          <label>UN Number</label>
                          <span className="required">*</span>
                          <AvField
                            type="number"
                            name="un_number"
                            id="un_number"
                            value={
                              this.state.enquiryDetails
                                ? this.state.enquiryDetails.un_number
                                : ""
                            }
                            validate={{
                              required: {
                                value: this.state.isDG,
                                errorMessage: "Please select UN Number",
                              },
                              pattern: {
                                value: "^s*([0-9]{4})$",
                                errorMessage: "Invalid UN Number",
                              },
                            }}
                          />
                        </AvGroup>
                      </div>

                      <div className="col-lg-3 col-sm-3 col-12">
                        <label>Hazardous Classes</label>
                        <span className="required">*</span>
                        <AvField
                          type="select"
                          name="imo_class"
                          value={
                            this.state.enquiryDetails
                              ? this.state.enquiryDetails.imo_class_id
                              : ""
                          }
                          validate={{
                            required: {
                              value: this.state.isDG,
                              errorMessage: "Please enter Hazardous Classes",
                            },
                          }}
                        >
                          {this.state.imo_classes.map((imo) => (
                            <option value={imo.id}>
                              ({imo.value}){imo.description}
                            </option>
                          ))}
                        </AvField>
                      </div>
                    </div>
                  ) : this.state.ContainerType === REFRIGERATED || this.state.ContainerType == OPENTOP || this.state.ContainerType == FLATRACK ? (
                    <div className="row">
                      <div className="col-lg-3 col-sm-3 col-12">
                        <AvGroup>
                          <label>{ this.state.ContainerType == REFRIGERATED ?  "Temperature ( ºC ) ": "Length" }</label>
                          <span className="required">*</span>
                          <AvField
                            type="text"
                            name="temperature"
                            value={
                              this.state.enquiryDetails &&
                              this.state.enquiryDetails.reefer_details
                                ? this.state.enquiryDetails.reefer_details[
                                    "temperature"
                                  ]
                                : ""
                            }
                            placeholder={ this.state.ContainerType == REFRIGERATED ? "Temperature":"Length"}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: this.state.ContainerType == REFRIGERATED ?  "Please enter Temperature" :  "Please enter Length" ,
                              },
                            }}
                          />
                        </AvGroup>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-12">
                        <AvGroup>
                          <label>{ this.state.ContainerType == REFRIGERATED ? "Humidity ( % )" :"Width" }</label>
                          <span className="required">*</span>
                          <AvField
                            type="text"
                            name="humidity"
                            value={
                              this.state.enquiryDetails &&
                              this.state.enquiryDetails.reefer_details
                                ? this.state.enquiryDetails.reefer_details[
                                    "humidity"
                                  ]
                                : ""
                            }
                            placeholder={ this.state.ContainerType == REFRIGERATED ? "Humidity":"Width"}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: this.state.ContainerType == REFRIGERATED ?  "Please enter Humidity" :  "Please enter Width",
                              },
                            }}
                          />
                        </AvGroup>
                      </div>

                      <div className="col-lg-3 col-sm-3 col-12">
                        <AvGroup>
                          <label>{ this.state.ContainerType == REFRIGERATED ? "Ventilation ( CBM / Per Hour )" :"Height" }</label>
                          <span className="required">*</span>
                          <AvField
                            type="text"
                            name="ventilation"
                            value={
                              this.state.enquiryDetails &&
                              this.state.enquiryDetails.reefer_details
                                ? this.state.enquiryDetails.reefer_details[
                                    "ventilation"
                                  ]
                                : ""
                            }
                            placeholder={ this.state.ContainerType == REFRIGERATED ? "Ventilation":"Height"}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: this.state.ContainerType == REFRIGERATED ?  "Please enter Ventilation" :  "Please enter Height",
                              },
                            }}
                          />
                        </AvGroup>
                      </div>
                    </div>
                  ) : null}

                  <div className="row">
                    <div className="col-lg-3 col-sm-3">
                      <div className="form-modelnew-inn">
                        <label>ETD at POL</label>
                        <DatePicker
                          className=""
                          selected={this.state.etdPol}
                          onChange={(date) =>
                            this.handleChange("etdPol", "etdPolError", date)
                          }
                          timeFormat="HH:mm"
                          dateFormat="d MMMM, yyyy"
                          required={true}
                          minDate={new Date()}
                          timeCaption="Time"
                          preventOpenOnFocus={true}
                          placeholderText={"ETD-at POL"}
                        />
                      </div>
                      <CustomFeedback>{this.state.etdPolError}</CustomFeedback>
                    </div>

                    {(user && user.company.company_type) === 2 ? (
                      <div className="col-lg-3 col-sm-3 col-12">
                        <label>
                          Shipper{" "}
                          <i
                            title="Add Shipper"
                            className="fa fa-plus-circle"
                            onClick={() => this.toggleContactModal(SHIPPER)}
                          />
                        </label>
                        <Select
                          className="home-select"
                          name="shipper"
                          ref={"shipper"}
                          value={this.state.selectedShipper}
                          placeholder="Select Shipper"
                          noOptionsMessage={() => "No record found"}
                          onChange={(selected) =>
                            this.handleChange(
                              "selectedShipper",
                              "shipperError",
                              selected
                            )
                          }
                          options={this.state.shipperList}
                        />
                        <CustomFeedback>
                          {this.state.shipperError}
                        </CustomFeedback>
                      </div>
                    ) : null}
                    <div className="col-lg-3 col-sm-3 col-12">
                      <AvGroup>
                        <label>Carrier / Shipping Line Preference</label>
                        <AvField
                          type="text"
                          name="carrier_preference"
                          ref={"carrier_preference"}
                          value={
                            this.state.enquiryDetails &&
                            this.state.enquiryDetails.details &&
                            this.state.enquiryDetails.details[
                              "carrier_preference"
                            ]
                              ? this.state.enquiryDetails.details[
                                  "carrier_preference"
                                ]
                              : ""
                          }
                          placeholder="Carrier / Shipping Line Preference"
                        />
                      </AvGroup>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-12">
                      <AvGroup>
                        <label>Preferred Empty Pick up Location</label>
                        <AvField
                          type="text"
                          name="pick_up_location"
                          value={
                            this.state.enquiryDetails &&
                            this.state.enquiryDetails.details &&
                            this.state.enquiryDetails.details[
                              "pick_up_location"
                            ]
                              ? this.state.enquiryDetails.details[
                                  "pick_up_location"
                                ]
                              : ""
                          }
                          placeholder="Enter the preferred Area of Pick up"
                        />
                      </AvGroup>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-12">
                      <AvGroup>
                        <label>Type of stuffing</label>
                        <span className="required">*</span>
                        <AvField
                          type="text"
                          name="stuffing"
                          value={
                            this.state.enquiryDetails &&
                            this.state.enquiryDetails.details
                              ? this.state.enquiryDetails.details["stuffing"]
                              : ""
                          }
                          placeholder="Factory Stuffing or CFS Stuffing"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter Type of Stuffing",
                            },
                          }}
                        />
                      </AvGroup>
                    </div>
                    <div className="col-lg-3 col-sm2-3 col-12">
                      <AvGroup>
                        <label>Stuffing location</label>
                        <span className="required">*</span>
                        <AvField
                          type="text"
                          name="stuffing_location"
                          value={
                            this.state.enquiryDetails &&
                            this.state.enquiryDetails.details
                              ? this.state.enquiryDetails.details[
                                  "stuffing_location"
                                ]
                              : ""
                          }
                          placeholder="Stuffing location"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter Stuffing location",
                            },
                          }}
                        />
                      </AvGroup>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-12">
                      <AvGroup>
                        <label>Vessel</label>
                        <AvField
                          type="text"
                          name="vessel"
                          className="text-touppercase"
                          value={
                            this.state.enquiryDetails &&
                            this.state.enquiryDetails.details &&
                            this.state.enquiryDetails.details["vessel"]
                              ? this.state.enquiryDetails.details["vessel"]
                              : ""
                          }
                          placeholder="Vessel Name or Service Name if available"
                        />
                      </AvGroup>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-12">
                      <AvGroup>
                        <label>Voyage</label>
                        <AvField
                          type="text"
                          name="voyage"
                          className="text-touppercase"
                          value={
                            this.state.enquiryDetails &&
                            this.state.enquiryDetails.details &&
                            this.state.enquiryDetails.details["voyage"]
                              ? this.state.enquiryDetails.details["voyage"]
                              : ""
                          }
                          placeholder="Voyage if available"
                        />
                      </AvGroup>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-12">
                      <label>Type of Service</label>
                      <span className="required">*</span>
                      <Select
                        className="home-select"
                        name="service"
                        value={this.state.selectedService}
                        placeholder="Select Service"
                        noOptionsMessage={() => "No record found"}
                        onChange={(selected) =>
                          this.handleChange(
                            "selectedService",
                            "serviceError",
                            selected
                          )
                        }
                        options={[
                          { label: "CY/CY", value: "CY/CY", key: "CY/CY" },
                          {
                            label: "CY/CFS",
                            value: "CY/CFS",
                            key: "CY/CFS",
                          },
                          { label: "CY/SD", value: "CY/SD", key: "CY/SD" },
                          {
                            label: "SD/CY",
                            value: "SD/CY",
                            key: "SD/CY",
                          },
                          { label: "CFS/CY", value: "CFS/CY", key: "CFS/CY" },
                          {
                            label: "CFS/CFS",
                            value: "CFS/CFS",
                            key: "CFS/CFS",
                          },
                          { label: "SD/SD", value: "SD/SD", key: "SD/SD" },
                        ]}
                      />
                      <CustomFeedback>{this.state.serviceError}</CustomFeedback>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-12">
                      <div className="commodity-box-one enquiry-view-text">
                        <label>Freight terms</label>
                        <span className="required">*</span>
                        <AvRadioGroup
                          inline
                          name="freight_terms"
                          value={
                            this.state.enquiryDetails
                              ? String(this.state.enquiryDetails.freight_terms)
                              : ""
                          }
                          onClick={this.handleFreightChange}
                          required
                          errorMessage="Pick one!"
                        >
                          <AvRadio customInput label="Prepaid" value="1" />
                          <AvRadio customInput label="Collect" value="2" />
                        </AvRadioGroup>
                        <CustomFeedback>
                          {this.state.freightTermError}
                        </CustomFeedback>
                      </div>
                    </div>
                    {this.state.freightTerm === 2 ? (
                      <div className="col-lg-3 col-sm-3 col-12">
                        <label>
                          Consignee{" "}
                          <i
                            title="Add Consignee"
                            className="fa fa-plus-circle"
                            onClick={() => this.toggleContactModal(CONSIGNEE)}
                          />
                        </label>
                        <Select
                          className="home-select"
                          name="consignee"
                          value={this.state.selectedConsignee}
                          placeholder="Select Consignee"
                          noOptionsMessage={() => "No record found"}
                          onChange={(selected) =>
                            this.handleChange(
                              "selectedConsignee",
                              "consigneeError",
                              selected
                            )
                          }
                          options={this.state.consigneeList}
                        />
                        <CustomFeedback>
                          {this.state.consigneeError}
                        </CustomFeedback>
                      </div>
                    ) : null}

                    <div className="col-lg-3 col-sm-3 col-12">
                      <label>
                        Forwarder{" "}
                        <i
                          title="Add Forwarder"
                          className="fa fa-plus-circle"
                          onClick={() => this.toggleContactModal(FORWARDER)}
                        />
                      </label>
                      <Select
                        className="home-select"
                        name="forwarder"
                        value={this.state.selectedForwarder}
                        placeholder="Select Forwarder"
                        noOptionsMessage={() => "No record found"}
                        onChange={(selected) =>
                          this.handleChange("selectedForwarder", "", selected)
                        }
                        options={this.state.forwarderList}
                      />
                    </div>

                    <div className="col-lg-3 col-sm-3 col-12">
                      <label>
                        CHA{" "}
                        <i
                          title="Add CHA"
                          className="fa fa-plus-circle"
                          onClick={() => this.toggleContactModal(CHA)}
                        />
                      </label>
                      <Select
                        className="home-select"
                        name="cha"
                        value={this.state.selectedCha}
                        placeholder="Select CHA"
                        noOptionsMessage={() => "No record found"}
                        onChange={(selected) =>
                          this.handleChange("selectedCha", "", selected)
                        }
                        options={this.state.chaList}
                      />
                    </div>
                    <div className="col-lg-3 col-sm-3 col-12">
                      <label>
                        Booking Party{" "}
                        <i
                          title="Add Booking Party"
                          className="fa fa-plus-circle"
                          onClick={() => this.toggleContactModal(BOOKING_PARTY)}
                        />
                        <span className="required">*</span>
                      </label>
                      <Select
                        className="home-select"
                        name="booking_party"
                        value={this.state.selectedBookingParty}
                        placeholder="Select Booking Party"
                        noOptionsMessage={() => "No record found"}
                        onChange={(selected) =>
                          this.handleChange(
                            "selectedBookingParty",
                            "bookingPartyError",
                            selected
                          )
                        }
                        options={this.state.bookingPartyList}
                      />
                      <CustomFeedback>
                        {this.state.bookingPartyError}
                      </CustomFeedback>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-12">
                      <label>
                        Invoice Party{" "}
                        <i
                          title="Add Invoice Party"
                          className="fa fa-plus-circle"
                          onClick={() => this.toggleContactModal(INVOICE_PARTY)}
                        />
                        <span className="required">*</span>
                      </label>
                      <Select
                        className="home-select"
                        name="booking_party"
                        value={this.state.selectedInvoiceParty}
                        placeholder="Select Invoice Party"
                        noOptionsMessage={() => "No record found"}
                        onChange={(selected) =>
                          this.handleChange(
                            "selectedInvoiceParty",
                            "invoicePartyError",
                            selected
                          )
                        }
                        options={this.state.invoicePartyList}
                      />
                      <CustomFeedback>
                        {this.state.invoicePartyError}
                      </CustomFeedback>
                    </div>

                    {this.state.selectedConsignee && this.state.freightTerm === 2 ? (
                      <div className="col-lg-3 col-sm-3 col-12">
                        <CustomInput
                          type="checkbox"
                          id="is_consignee_approval"
                          label="Do you want to send booking mail to Consignee?"
                          onChange={(event) =>
                            this.handleApproval(event, "isConsigneeMail")
                          }
                          checked={this.state.isConsigneeMail}
                        />
                      </div>
                    ) : null}

                    <div className="col-lg-6 col-sm-6 col-12 confirm-booking-new-table-date">
                      <label>Position</label>
                      <span className="required">*</span>
                      <div className="row">
                        <div className="col-lg-6 col-sm-6 col-12">
                          <DatePicker
                            selected={this.state.startDate}
                            onChange={(date) =>
                              this.handleDateChange(
                                date,
                                "startDate",
                                "startDateError"
                              )
                            }
                            selectsStart
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={60}
                            dateFormat="d MMMM, yyyy HH:mm"
                            timeCaption="Time"
                            preventOpenOnFocus={true}
                            placeholderText={"Pick-up Date"}
                            startDate={this.state.startDate}
                            minDate={new Date()}
                            endDate={this.state.endDate}
                            //onKeyDown={(e) => e.preventDefault()}
                          />
                          <CustomFeedback>
                            {this.state.startDateError}
                          </CustomFeedback>
                        </div>

                        <div className="col-lg-6 col-sm-6 col-12">
                          <DatePicker
                            className="date-input"
                            selected={this.state.endDate}
                            onChange={(date) =>
                              this.handleDateChange(
                                date,
                                "endDate",
                                "endDateError"
                              )
                            }
                            selectsEnd
                            showTimeSelect
                            timeFormat="HH:mm"
                            minDate={this.state.startDate}
                            timeIntervals={60}
                            dateFormat="d MMMM, yyyy HH:mm"
                            timeCaption="Time"
                            preventOpenOnFocus={true}
                            placeholderText={"Gate-in Date"}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                           // onKeyDown={(e) => e.preventDefault()}
                          />

                          <CustomFeedback>
                            {this.state.endDateError}
                          </CustomFeedback>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-12">
                      <AvGroup className="error-t-negative remark">
                        <label>
                          Goods Description (Max 1000 characters allowed)
                        </label>
                        <span className="required">*</span>
                        <AvField
                          name="goods_description"
                          id="goods_description"
                          type="textarea"
                          value={
                            this.state.enquiryDetails
                              ? this.state.enquiryDetails.goods_description
                              : ""
                          }
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter Goods Description",
                            },
                            maxLength: {
                              value: 1000,
                              errorMessage:
                                "You have reached your maximum limit of characters allowed",
                            },
                          }}
                        />
                      </AvGroup>
                    </div>
                    <div className="col-lg-12 col-sm-12 text-box">
                      <AvGroup className="error-t-negative remark">
                        <label>Remarks (Max 1500 characters allowed)</label>
                        <span className="required">*</span>
                        <AvField
                          name="remarks"
                          id="remarks"
                          type="textarea"
                          placeholder="free at destination"
                          value={
                            this.state.enquiryDetails
                              ? this.state.enquiryDetails.remarks
                              : ""
                          }
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter Remarks",
                            },
                            maxLength: {
                              value: 1500,
                              errorMessage:
                                "You have reached your maximum limit of characters allowed",
                            },
                          }}
                        />
                      </AvGroup>
                    </div>
                  </div>

                  <div className="row align-freight">
                    <div className="col-lg-6 col-sm-6 border-right1 ">
                      {!this.state.isFreightAgreed ? (
                        <CustomInput
                          type="checkbox"
                          id="is_freight_request"
                          label="Freight Required or Requested"
                          onChange={(event) =>
                            this.handleApproval(event, "isFreightCharges")
                          }
                          checked={this.state.isFreightCharges}
                        />
                      ) : null}

                      {this.state.isFreightCharges ? (
                        <Fragment>
                          <div className="row align-freight">
                            <div className="col-lg-12 col-sm-12">
                              <label>
                                Freight and related: &nbsp;&nbsp;&nbsp; USD
                              </label>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                              <label>
                                Per{" "}
                                {this.state.ContainerType == REFRIGERATED
                                    ? "20 RF" 
                                    : this.state.ContainerType == OPENTOP ? "20 OT" : this.state.ContainerType == FLATRACK ? "20 FR" : "20 FT"}{" "}
                                Container{" "}
                              </label>
                            </div>

                            <div className="col-lg-6 col-sm-6">
                              <AvGroup>
                                <AvField
                                  type="text"
                                  name="freight_chrages_20"
                                  value=""
                                  placeholder=""
                                />
                              </AvGroup>
                            </div>
                          </div>
                          {this.state.ContainerType === REFRIGERATED ? (
                            <div className="row align-freight">
                              <div className="col-lg-6 col-sm-6">
                                <label>
                                  Per{" "}
                                  {this.state.ContainerType == REFRIGERATED
                                        ? "40 RF HC"
                                        : this.state.ContainerType == OPENTOP ? "40 OT" : this.state.ContainerType == FLATRACK ? "40 FR (Collapsible)" : "40 FT HC"}{" "}
                                  Container{" "}
                                </label>
                              </div>

                              <div className="col-lg-6 col-sm-6">
                                <AvGroup>
                                  <AvField
                                    type="text"
                                    name="freight_chrages_40hc"
                                    value=""
                                    placeholder=""
                                  />
                                </AvGroup>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="col-lg-4 col-sm-64">
                                <label>
                                  Per{" "}
                                  {this.state.ContainerType == REFRIGERATED
                                    ? "40 RF"
                                    : this.state.ContainerType == OPENTOP ? "40 OT" : this.state.ContainerType == FLATRACK ? "40 FR (Collapsible)" : "40 FT"}{" "}
                                  Container{" "}
                                </label>
                                <AvField
                                  type="text"
                                  name="freight_chrages_40"
                                  value=""
                                  placeholder=""
                                />
                              </div>

                              <div className="col-lg-4 col-sm-4">
                                <label>
                                  Per{" "}
                                  {this.state.ContainerType == REFRIGERATED
                                    ? "40 RF"
                                    : this.state.ContainerType == OPENTOP ? "40 OT HC" : this.state.ContainerType == FLATRACK ? "40 FL HC" : "40 FT HC"}{" "}
                                  Container{" "}
                                </label>
                                <AvField
                                  type="text"
                                  name="freight_chrages_40hc"
                                  value=""
                                  placeholder=""
                                />
                              </div>
                            </>
                          )}

                          {this.state.ContainerType == FLATRACK ? (<>
                              <div className="col-lg-4 col-sm-4">
                                <label>
                                  Per{" "}
                                    40 SR{" "}
                                  Container{" "}
                                </label>
                                <AvField
                                  type="text"
                                  name="freight_chrages_40sr"
                                  value=""
                                  placeholder=""
                                />
                              </div>
                          </> ) : <></> }

                          <div className="row align-freight">
                            <div className="col-lg-6 col-sm-6">
                              Locals at POL and POD
                            </div>
                            <div className="col-lg-3 col-sm-3">As Actuals</div>
                            <div className="col-lg-3 col-sm-3">
                              <label>Per Container </label>
                            </div>
                          </div>
                          <div className="row align-freight">
                            <div className="col-lg-6 col-sm-6">
                              ENS/ACD/DOC at POL and POD
                            </div>
                            <div className="col-lg-3 col-sm-3">As Actuals</div>
                            <div className="col-lg-3 col-sm-3">
                              <label>Per Bill of lading </label>
                            </div>
                          </div>
                        </Fragment>
                      ) : null}
                    </div>

                    <div className="col-lg-6 col-sm-6 border-right1">
                      {!this.state.isFreightCharges ? (
                        <CustomInput
                          type="checkbox"
                          id="is_freight_agreed"
                          label="Freight Agreed"
                          onChange={(event) =>
                            this.handleApproval(event, "isFreightAgreed")
                          }
                          checked={this.state.isFreightAgreed}
                        />
                      ) : null}

{this.state.isFreightAgreed ? (
                          <Fragment>
                            <div className="row">
                              <div className="col-lg-12 col-sm-12">
                                <div className="freight-label-box">
                                  <label>
                                    Freight and related: <b>USD</b>
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-4 col-sm-4">
                                <label>
                                  Per{" "}
                                  {this.state.ContainerType == REFRIGERATED
                                    ? "20 RF"
                                    : this.state.ContainerType == OPENTOP ? "20 OT" : this.state.ContainerType == FLATRACK ? "20 FR " : "20 FT"}{" "}
                                  Container{" "}
                                </label>
                                <AvField
                                  type="text"
                                  name="user_freight_agreed_chrages_20"
                                  value=""
                                  placeholder=""
                                />
                              </div>

                              {this.state.ContainerType == REFRIGERATED ? (
                                <>
                                  <div className="col-lg-4 col-sm-4">
                                    <label>
                                      Per{" "}
                                      {this.state.ContainerType == REFRIGERATED
                                        ? "40 RF HC"
                                        : "40 FT HC"}{" "}
                                      Container{" "}
                                    </label>
                                    <AvField
                                      type="text"
                                      name="user_freight_agreed_chrages_40hc"
                                      value=""
                                      placeholder=""
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-lg-4 col-sm-4">
                                    <label>
                                      Per{" "}
                                      {this.state.ContainerType == REFRIGERATED
                                        ? "40 RF"
                                        : this.state.ContainerType == OPENTOP ? "40 OT" : this.state.ContainerType == FLATRACK ? "40 FR (Collapsible) " : "40 FT"}{" "}
                                      Container{" "}
                                    </label>
                                    <AvField
                                      type="text"
                                      name="user_freight_agreed_chrages_40"
                                      value=""
                                      placeholder=""
                                    />
                                  </div>

                                  <div className="col-lg-4 col-sm-4">
                                    <label>
                                      Per{" "}
                                      {this.state.ContainerType == REFRIGERATED
                                        ? "40 RF"
                                        : this.state.ContainerType == OPENTOP ? "40 OT HC" : this.state.ContainerType == FLATRACK ? "40 FL HC" : "40 FT HC"}{" "}
                                      Container{" "}
                                    </label>
                                    <AvField
                                      type="text"
                                      name="user_freight_agreed_chrages_40hc"
                                      value=""
                                      placeholder=""
                                    />
                                  </div>

                                  {this.state.ContainerType == FLATRACK ? (<>
                                    <div className="col-lg-4 col-sm-4">
                                        <label>
                                          Per{" "}
                                          40 SR{" "}
                                          Container{" "}
                                        </label>
                                        <AvField
                                          type="text"
                                          name="user_freight_agreed_chrages_40sr"
                                          value=""
                                          placeholder=""
                                        />
                                      </div>
                                  </> ) : <></> }
                                  
                                </>
                              )}
                            </div>

                            <div className="row">
                              <div className="col-lg-4 col-sm-4">
                                Locals at POL and POD
                              </div>
                              <div className="col-lg-4 col-sm-4">
                                As Actuals
                              </div>
                              <div className="col-lg-4 col-sm-4">
                                <label>Per Container </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-4 col-sm-4">
                                ENS/ACD/DOC at POL and POD
                              </div>
                              <div className="col-lg-4 col-sm-4">
                                As Actuals
                              </div>
                              <div className="col-lg-4 col-sm-4">
                                <label>Per Bill of lading </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 col-sm-12">
                                Remarks:
                                <AvField
                                  // type="text"
                                  name="freight_aggreed_remark"
                                  type="textarea"
                                  value=""
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </Fragment>
                        ) : null}
                    </div>
                  </div>

                  <br />
                  <CustomFeedback>{this.state.containerError}</CustomFeedback>

                  <Button color="primary">
                    <ButtonLoader
                      isLoading={this.state.buttonLoading}
                      label={"Submit"}
                    />
                  </Button>
                </AvForm>
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />
        {this.state.modalContactOpen ? (
          <ContactModal
            addAddress={this.addAddress}
            countryList={this.state.countryList}
            stateList={this.state.stateList}
            cityList={this.state.cityList}
            country={this.state.country}
            modalOpen={this.state.modalContactOpen}
            action={this.state.action}
            toggleAddModal={this.toggleContactModal}
            modalLoading={this.state.modalLoading}
            selectedContactType={this.state.selectedContactType}
          />
        ) : null}
      </Fragment>
    ) : (
      <Fragment />
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStateToProps, {})(AddEnquiry);
