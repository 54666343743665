export const containerTypes = {
    1: "Dry",
    2: "Refrigerated",
   /* 3: "Overdimensional",*/
    4: "Hazardous",
    5: "Open Top",
    6: "Flat Rack",
};

const getKeyByValue = (value) => {
    for (let key in containerTypes)
        if (containerTypes[key] === value)
            return parseInt(key);
    return false;
};

export const DRY = getKeyByValue('Dry');
export const REFRIGERATED = getKeyByValue('Refrigerated');
export const HAZARDOUS = getKeyByValue('Hazardous');
export const OPENTOP = getKeyByValue('Open Top');
export const FLATRACK = getKeyByValue('Flat Rack');
/*export const OVERDIMENSIONAL = getKeyByValue('Overdimensional');*/

